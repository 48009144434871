import {inject, Injectable} from '@angular/core';
import {
  Observable,
  of,
  take,
  ReplaySubject,
  tap,
  first,
  takeUntil,
  switchMap,
  filter,
  throwError,
  catchError, BehaviorSubject, timer, finalize, takeWhile, Subject
} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {CookieService} from "ngx-cookie-service";
import {DOCUMENT} from '@angular/common';
import {Profile} from "../interface/profile";
import {environment} from "../../../environments/environment";
import {UserService} from "./user/user.service";
import {User} from "./user/user.types";
import {UserPermissions} from "../interface/user-permissions";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _authenticated: boolean = false;
  private _apiUrl = environment.apiUrl;
  private _cookie = inject(CookieService);
  private _userService = inject(UserService);
  private _document = inject(DOCUMENT)
  private headers: HttpHeaders;
  private _httpClient = inject(HttpClient);
  private _permission: BehaviorSubject<UserPermissions | null> = new BehaviorSubject(null);
  private _profile: BehaviorSubject<Profile> = new BehaviorSubject(null);

  countdown: number = 5;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  set accessToken(token){
    localStorage.setItem('accessToken', token);
  }
  get accessToken(): string
  {
    return localStorage.getItem('accessToken') ?? '';
  }

  set userUuid(userUuid){
    localStorage.setItem('userUuid', userUuid);
  }
  get userUuid(): string
  {
    return localStorage.getItem('userUuid') ?? '';
  }

  set user(user: Profile){
    localStorage.setItem('user', JSON.stringify(user));
  }
  get user(): User {
    return JSON.parse(localStorage.getItem('user'));
  }

  get profile(): Observable<Profile> {
    return this._profile.asObservable();
  }

  set partnerUuid(partnerUuid){
    localStorage.setItem('partnerUuid', partnerUuid);
  }

  get partnerUuid(): string
  {
    return localStorage.getItem('partnerUuid');
  }

  set receivablesBalance(receivablesBalance){
    localStorage.setItem('receivablesBalance', receivablesBalance);
  }

  get receivablesBalance(): string
  {
    return localStorage.getItem('receivablesBalance');
  }

  set ofwcaUuid(ofwcaUuid){
    localStorage.setItem('ofwcaUuid', ofwcaUuid);
  }

  get ofwcaUuid(): string
  {
    return localStorage.getItem('ofwcaUuid') ?? '';
  }

  set userPermission(userPermission: UserPermissions){
    localStorage.setItem('userPermission',  JSON.stringify(userPermission));
  }

  get userPermission(): string
  {
    return localStorage.getItem('userPermission') ?? '';
  }

  get headers$(){
    this.headers = new HttpHeaders()
      .set('authorization', 'Bearer ' + this.accessToken)
    return {headers: this.headers}
  }

  signOut(): Observable<any>
  {

    this._httpClient.post(this._apiUrl + '/rest/sso/logout', '')
      .pipe(take(1))
      .subscribe(() => {});

    this._httpClient.post('/rest/crm/logout', '',)
      .pipe(take(1))
      .subscribe(() => {});
    this._cookie.deleteAll();
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userUuid');
    localStorage.removeItem('partnerUuid');
    localStorage.removeItem('contractBalanceToPayNet');
    localStorage.removeItem('commissionContractSettlementsUuid');
    localStorage.removeItem('userPermission');
    localStorage.removeItem('user');
    localStorage.removeItem('ofwcaUuid');
    this._authenticated = false;

    timer(150, 150)
      .pipe(
        finalize(() => {
          this._document.location.href = this._apiUrl + '/sso/';
        }),
        takeWhile(() => this.countdown > 0),
        takeUntil(this._unsubscribeAll),
        tap(() => this.countdown--)
      )
      .subscribe();

    return of(false);
  }

  check(): Observable<boolean>
  {
    if (!this.userUuid)
    {
      return of(false);
    }

    if (!this.accessToken)
    {
      return of(false);
    }

    return of(true);
  }

  signIn({href}: { href: any }): Observable<any> {
    if ( this._authenticated )
    {
      return throwError('User is already logged in.');
    }

    return this._httpClient.post(`/rest/crm${href}`, href)
      .pipe(
        switchMap((response) => {
          this.accessToken = response['token'];
          this.userUuid = response['user'].userId;

          this._authenticated = true;
          this.getProfile(false).subscribe();
          this._userService.getUser().subscribe();
          return of(response);
        })
      );
  }

  getProfile(refresh = true): Observable<Profile> {
    if (refresh) {
      // this.refreshToken().pipe(first()).subscribe();
    }
    this._permission.next({
      partner: {
        read: false,
        read_report: false,
        create: false,
        write: false,
        delete: false,
        regional_change: false,
        admin_root: false,
      },
      facility: {
        read: false,
        read_report: false,
        create: false,
        write: false,
        delete: false,
        admin_root: false,
      },
      contract: {
        read: false,
        read_report: false,
        create: false,
        write: false,
        delete: false,
        admin_root: false,
      },
      department: {
        read: false,
        read_report: false,
        create: false,
        write: false,
        delete: false,
        admin_root: false,
      },
      document: {
        read: false,
        read_report: false,
        create: false,
        write: false,
        delete: false,
        admin_root: false,
      },
      insurance_company: {
        read: false,
        read_report: false,
        create: false,
        write: false,
        delete: false,
        admin_root: false,
      },
      ofwca: {
        read: false,
        read_report: false,
        create: false,
        write: false,
        delete: false,
        regional_change: false,
        admin_root: false,
      },
      template: {
        read: false,
        read_report: false,
        create: false,
        write: false,
        delete: false,
        admin_root: false,
      },
      user: {
        read: false,
        read_report: false,
        create: false,
        write: false,
        delete: false,
        admin_root: false,
      },
      krk: {
        read: false,
        read_report: false,
        create: false,
        write: false,
        delete: false,
        admin_root: false,
      },
      registration: {
        read: false,
        read_report: false,
        create: false,
        write: false,
        delete: false,
        id_import: false,
        admin_root: false,
      },
      deregistration: {
        read: false,
        read_report: false,
        create: false,
        write: false,
        delete: false,
        admin_root: false,
      },
      meeting: {
        read: false,
        read_report: false,
        create: false,
        write: false,
        delete: false,
        read_and_sign_up: false,
        admin_root: false,
      },
      idd_training: {
        read: false,
        read_report: false,
        create: false,
        write: false,
        delete: false,
        read_and_sign_up: false,
        admin_root: false,
      },
      sold_policy: {
        read: false,
        read_report: false,
        create: false,
        write: false,
        delete: false,
        read_and_sign_up: false,
        policy_import: false,
        admin_root: false,
      },
      settlement: {
        read: false,
        read_report: false,
        create: false,
        write: false,
        delete: false,
        margin: false,
        admin_root: false,
      },
      commission_redirection: {
        read: false,
        read_report: false,
        create: false,
        write: false,
        delete: false,
        import: false,
        admin_root: false,
      },
      newsletter: {
        read: false,
        read_report: false,
        create: false,
        write: false,
        delete: false,
        import: false,
        admin_root: false,
      },
      licensed_agent: {
        read: false,
        status_editing: false,
        admin_root: false,
      },
      support: {
        read: false,
        read_report: false,
        create: false,
        write: false,
        delete: false,
        admin_root: false,
      },
      analysis: {
        read_report: false,
        admin_root: false,
      },
      partner_search_engine: {
        read: false,
        admin_root: false,
      },
      employees: {
        read: false,
        read_report: false,
        admin_root: false,
      },
      block_ofwca: {
        delete: false,
        admin_root: false,
      },
      corporation: {
        read: false,
        read_report: false,
        create: false,
        write: false,
        delete: false,
        admin_root: false,
      },
      asf_360: {
        read: false,
        create: false,
        write: false,
        delete: false,
        admin_root: false,
      },
      receivables: {
        read: false,
        create: false,
        write: false,
        delete: false,
        admin_root: false,
      }
    });
    return this._httpClient.get<Profile>('/rest/crm/oauth/profile')
      .pipe(
        tap((response) => {
          this._authenticated = true;
          this.userUuid = response.userId;
          this.partnerUuid = response.partnerId ?? '';
          this.ofwcaUuid = response.ofwcaId ?? '';
          this.receivablesBalance = response.receivablesBalance ?? '';
          this.user = response;
          this._profile.next(response);
          this.calculatePermissions(response);
        })
      );
  }

  refreshToken(): Observable<any> {
    this.auth360();
    return this._httpClient.get( '/rest/crm/oauth/refresh-token')
      .pipe(
        first(),
        tap((response) => {
          // this.accessToken = response['access_token'];
          this._authenticated = true;
        })
      )
  }

  auth360(): void {
    this._httpClient.get('/rest/crm/auth'
    )
      .pipe(first())
      .subscribe((response) => {
        return response;
      });
  }

  get userPermissions$(): Observable<UserPermissions> {
    return this._permission.asObservable();
  }

  private calculatePermissions(profile: Profile): void {
    const permissions: UserPermissions = {
      partner: {
        read: ['read', 'read_report', 'create', 'write', 'full', 'regional_change', 'admin_root'].includes(profile.permissions.partner),
        read_report: ['read_report', 'create', 'write', 'full', 'regional_change', 'admin_root'].includes(profile.permissions.partner)
          && !profile.reportPermissions.includes('partner'),
        create: ['create', 'write', 'full', 'regional_change', 'admin_root'].includes(profile.permissions.partner),
        write: ['write', 'full', 'regional_change', 'admin_root'].includes(profile.permissions.partner),
        delete: ['full', 'regional_change', 'admin_root'].includes(profile.permissions.partner),
        regional_change: ['regional_change', 'admin_root'].includes(profile.permissions.partner),
        admin_root: ['admin_root'].includes(profile.permissions.partner)
      },
      facility: {
        read: ['read', 'read_report', 'create', 'write', 'full', 'admin_root'].includes(profile.permissions.facility),
        read_report: ['read_report', 'create', 'write', 'full', 'admin_root'].includes(profile.permissions.facility)
          && !profile.reportPermissions.includes('facility'),
        create: ['create', 'write', 'full', 'admin_root'].includes(profile.permissions.facility),
        write: ['write', 'full', 'admin_root'].includes(profile.permissions.facility),
        delete: ['full', 'admin_root'].includes(profile.permissions.facility),
        admin_root: ['admin_root'].includes(profile.permissions.facility)
      },
      contract: {
        read: ['read', 'read_report', 'create', 'write', 'full', 'admin_root'].includes(profile.permissions.contract),
        read_report: ['read_report', 'create', 'write', 'full', 'admin_root'].includes(profile.permissions.contract)
          && !profile.reportPermissions.includes('contract'),
        create: ['create', 'write', 'full', 'admin_root'].includes(profile.permissions.contract),
        write: ['write', 'full', 'admin_root'].includes(profile.permissions.contract),
        delete: ['full', 'admin_root'].includes(profile.permissions.contract),
        admin_root: ['admin_root'].includes(profile.permissions.contract),
      },
      department: {
        read: ['read', 'read_report', 'create', 'write', 'full', 'admin_root'].includes(profile.permissions.department),
        read_report: ['read_report', 'create', 'write', 'full', 'admin_root'].includes(profile.permissions.department)
          && !profile.reportPermissions.includes('department'),
        create: ['create', 'write', 'full', 'admin_root'].includes(profile.permissions.department),
        write: ['write', 'full', 'admin_root'].includes(profile.permissions.department),
        delete: ['full', 'admin_root'].includes(profile.permissions.department),
        admin_root: ['admin_root'].includes(profile.permissions.department)
      },
      document: {
        read: ['read', 'read_report', 'create', 'write', 'full', 'admin_root'].includes(profile.permissions.document),
        read_report: ['read_report', 'create', 'write', 'full', 'admin_root'].includes(profile.permissions.document)
          && !profile.reportPermissions.includes('document'),
        create: ['create', 'write', 'full', 'admin_root'].includes(profile.permissions.document),
        write: ['write', 'full', 'admin_root'].includes(profile.permissions.document),
        delete: ['full', 'admin_root'].includes(profile.permissions.document),
        admin_root: ['admin_root'].includes(profile.permissions.document)
      },
      insurance_company: {
        read: ['read', 'read_report', 'create', 'write', 'full', 'admin_root'].includes(profile.permissions.insurance_company),
        read_report: ['read_report', 'create', 'write', 'full', 'admin_root'].includes(profile.permissions.insurance_company)
          && !profile.reportPermissions.includes('insurance_company'),
        create: ['create', 'write', 'full', 'admin_root'].includes(profile.permissions.insurance_company),
        write: ['write', 'full', 'admin_root'].includes(profile.permissions.insurance_company),
        delete: ['full', 'admin_root'].includes(profile.permissions.insurance_company),
        admin_root: ['admin_root'].includes(profile.permissions.insurance_company)
      },
      ofwca: {
        limited_read: ['regional_change', 'limited_read', 'read',
          'read_report', 'create', 'write', 'full', 'admin_root'].includes(profile.permissions.ofwca),
        read: ['regional_change', 'read', 'read_report', 'create', 'write', 'full', 'admin_root'].includes(profile.permissions.ofwca),
        read_report: ['regional_change', 'read_report', 'create', 'write', 'full', 'admin_root'].includes(profile.permissions.ofwca)
          && !profile.reportPermissions.includes('ofwca'),
        create: ['regional_change', 'create', 'write', 'full', 'admin_root'].includes(profile.permissions.ofwca),
        write: ['regional_change', 'write', 'full', 'admin_root'].includes(profile.permissions.ofwca),
        delete: ['regional_change', 'full', 'admin_root'].includes(profile.permissions.ofwca),
        regional_change: ['regional_change', 'admin_root'].includes(profile.permissions.ofwca),
        admin_root: ['admin_root'].includes(profile.permissions.ofwca)
      },
      template: {
        read: ['read', 'read_report', 'create', 'write', 'full', 'admin_root'].includes(profile.permissions.template),
        read_report: ['read_report', 'create', 'write', 'full', 'admin_root'].includes(profile.permissions.template)
          && !profile.reportPermissions.includes('template'),
        create: ['create', 'write', 'full', 'admin_root'].includes(profile.permissions.template),
        write: ['write', 'full', 'admin_root'].includes(profile.permissions.template),
        delete: ['full', 'admin_root'].includes(profile.permissions.template),
        admin_root: ['admin_root'].includes(profile.permissions.template)
      },
      user: {
        read: ['read', 'read_report', 'create', 'write', 'full', 'admin_root'].includes(profile.permissions.user),
        read_report: ['read_report', 'create', 'write', 'full', 'admin_root'].includes(profile.permissions.user)
          && !profile.reportPermissions.includes('user'),
        create: ['create', 'write', 'full', 'admin_root'].includes(profile.permissions.user),
        write: ['write', 'full', 'admin_root'].includes(profile.permissions.user),
        delete: ['full', 'admin_root'].includes(profile.permissions.user),
        admin_root: ['admin_root'].includes(profile.permissions.user)
      },
      krk: {
        read: ['read', 'read_report', 'create', 'write', 'full', 'admin_root'].includes(profile.permissions.krk),
        read_report: ['read_report', 'create', 'write', 'full', 'admin_root'].includes(profile.permissions.krk)
          && !profile.reportPermissions.includes('krk'),
        create: ['create', 'write', 'full', 'admin_root'].includes(profile.permissions.krk),
        write: ['write', 'full', 'admin_root'].includes(profile.permissions.krk),
        delete: ['full', 'admin_root'].includes(profile.permissions.krk),
        admin_root: ['admin_root'].includes(profile.permissions.krk)
      },
      registration: {
        read: ['read', 'read_report', 'create', 'write', 'full', 'id_import', 'admin_root'].includes(profile.permissions.registration),
        read_report: ['read_report', 'create', 'write', 'full', 'id_import', 'admin_root'].includes(profile.permissions.registration)
          && !profile.reportPermissions.includes('registration'),
        create: ['create', 'write', 'full', 'id_import', 'admin_root'].includes(profile.permissions.registration),
        write: ['write', 'full', 'id_import', 'admin_root'].includes(profile.permissions.registration),
        delete: ['full', 'id_import', 'admin_root'].includes(profile.permissions.registration),
        id_import: ['id_import', 'admin_root'].includes(profile.permissions.registration),
        admin_root: ['admin_root'].includes(profile.permissions.registration)
      },
      deregistration: {
        read: ['read', 'read_report', 'create', 'write', 'full', 'admin_root'].includes(profile.permissions.deregistration),
        read_report: ['read_report', 'create', 'write', 'full', 'admin_root'].includes(profile.permissions.deregistration)
          && !profile.reportPermissions.includes('deregistration'),
        create: ['create', 'write', 'full', 'admin_root'].includes(profile.permissions.deregistration),
        write: ['write', 'full', 'admin_root'].includes(profile.permissions.deregistration),
        delete: ['full', 'admin_root'].includes(profile.permissions.deregistration),
        admin_root: ['admin_root'].includes(profile.permissions.deregistration)
      },
      meeting: {
        read: ['read', 'read_and_sign_up', 'read_report', 'create', 'write', 'full', 'admin_root'].includes(profile.permissions.meeting),
        read_and_sign_up: ['read_and_sign_up', 'read_report', 'create', 'write', 'full',
          'admin_root'].includes(profile.permissions.meeting),
        read_report: ['read_report', 'create', 'write', 'full', 'admin_root'].includes(profile.permissions.meeting)
          && !profile.reportPermissions.includes('meeting'),
        create: ['create', 'write', 'full', 'admin_root'].includes(profile.permissions.meeting),
        write: ['write', 'full', 'admin_root'].includes(profile.permissions.meeting),
        delete: ['full', 'admin_root'].includes(profile.permissions.meeting),
        admin_root: ['admin_root'].includes(profile.permissions.meeting)
      },
      idd_training: {
        read: ['read', 'read_report', 'create', 'write', 'full', 'admin_root'].includes(profile.permissions.idd_training),
        read_report: ['read_report', 'create', 'write', 'full', 'admin_root'].includes(profile.permissions.idd_training)
          && !profile.reportPermissions.includes('idd_training'),
        create: ['create', 'write', 'full', 'admin_root'].includes(profile.permissions.idd_training),
        write: ['write', 'full', 'admin_root'].includes(profile.permissions.idd_training),
        delete: ['full', 'admin_root'].includes(profile.permissions.idd_training),
        admin_root: ['admin_root'].includes(profile.permissions.idd_training)
      },
      sold_policy: {
        read: ['read', 'read_report', 'policy_import', 'admin_root'].includes(profile.permissions.sold_policy),
        read_report: ['read_report', 'policy_import', 'admin_root'].includes(profile.permissions.sold_policy)
          && !profile.reportPermissions.includes('sold_policy'),
        create: ['policy_import', 'admin_root'].includes(profile.permissions.sold_policy),
        write: ['policy_import', 'admin_root'].includes(profile.permissions.sold_policy),
        delete: ['policy_import', 'admin_root'].includes(profile.permissions.sold_policy),
        policy_import: ['policy_import', 'admin_root'].includes(profile.permissions.sold_policy),
        admin_root: ['admin_root'].includes(profile.permissions.sold_policy)
      },
      settlement: {
        read: ['read', 'read_report', 'create', 'write', 'full', 'margin', 'admin_root'].includes(profile.permissions.settlement),
        read_report: ['read_report', 'create', 'write', 'full', 'margin', 'admin_root'].includes(profile.permissions.settlement)
          && !profile.reportPermissions.includes('settlement'),
        create: ['create', 'write', 'full', 'margin', 'admin_root'].includes(profile.permissions.settlement),
        write: ['write', 'full', 'margin', 'admin_root'].includes(profile.permissions.settlement),
        delete: ['full', 'margin', 'admin_root'].includes(profile.permissions.settlement),
        margin: ['margin', 'admin_root'].includes(profile.permissions.settlement),
        admin_root: ['admin_root'].includes(profile.permissions.settlement),
      },
      commission_redirection: {
        read: ['read', 'read_report', 'create', 'write', 'full', 'import',
          'admin_root'].includes(profile.permissions.commission_redirection),
        read_report: ['read_report', 'create', 'write', 'full', 'import', 'admin_root'].includes(profile.permissions.commission_redirection)
          && !profile.reportPermissions.includes('commission_redirection'),
        create: ['create', 'write', 'full', 'import', 'admin_root'].includes(profile.permissions.commission_redirection),
        write: ['write', 'full', 'import', 'admin_root'].includes(profile.permissions.commission_redirection),
        delete: ['full', 'import', 'admin_root'].includes(profile.permissions.commission_redirection),
        import: ['import', 'admin_root'].includes(profile.permissions.commission_redirection),
        admin_root: ['admin_root'].includes(profile.permissions.commission_redirection)
      },
      newsletter: {
        read: ['read', 'read_report', 'create', 'write', 'full', 'import', 'admin_root'].includes(profile.permissions.newsletter),
        read_report: ['read_report', 'create', 'write', 'full', 'import', 'admin_root'].includes(profile.permissions.newsletter)
          && !profile.reportPermissions.includes('newsletter'),
        create: ['create', 'write', 'full', 'import', 'admin_root'].includes(profile.permissions.newsletter),
        write: ['write', 'full', 'import', 'admin_root'].includes(profile.permissions.newsletter),
        delete: ['full', 'import', 'admin_root'].includes(profile.permissions.newsletter),
        import: ['import', 'admin_root'].includes(profile.permissions.newsletter),
        admin_root: ['admin_root'].includes(profile.permissions.newsletter)
      },
      licensed_agent: {
        read: ['read', 'status_editing', 'admin_root'].includes(profile.permissions.licensed_agent),
        status_editing: ['status_editing', 'admin_root'].includes(profile.permissions.licensed_agent),
        admin_root: ['admin_root'].includes(profile.permissions.licensed_agent)
      },
      support: {
        read: ['read', 'read_report', 'create', 'write', 'full', 'admin_root'].includes(profile.permissions.support),
        read_report:  ['read_report', 'create', 'write', 'full', 'admin_root'].includes(profile.permissions.support)
          && !profile.reportPermissions.includes('support'),
        create:  ['create', 'write', 'full', 'admin_root'].includes(profile.permissions.support),
        write: ['write', 'full', 'admin_root'].includes(profile.permissions.support),
        delete:  ['full', 'admin_root'].includes(profile.permissions.support),
        admin_root: ['admin_root'].includes(profile.permissions.support),
      },
      analysis: {
        read_report:  ['read_report', 'admin_root'].includes(profile.permissions.analysis),
        admin_root: ['admin_root'].includes(profile.permissions.analysis),
      },
      partner_search_engine: {
        read: ['read', 'admin_root'].includes(profile.permissions.partner_search_engine),
        admin_root: ['admin_root'].includes(profile.permissions.partner_search_engine),
      },
      employees: {
        read: ['read', 'read_report', 'admin_root'].includes(profile.permissions.employees),
        read_report:  ['read_report', 'admin_root'].includes(profile.permissions.employees)
          && !profile.reportPermissions.includes('employees'),
        admin_root: ['admin_root'].includes(profile.permissions.employees),
      },
      block_ofwca: {
        delete:  ['full', 'admin_root'].includes(profile.permissions.block_ofwca),
        admin_root: ['admin_root'].includes(profile.permissions.block_ofwca),
      },
      corporation: {
        read: ['read', 'read_report', 'create', 'write', 'full', 'admin_root'].includes(profile.permissions.corporation),
        read_report:  ['read_report', 'create', 'write', 'full', 'admin_root'].includes(profile.permissions.corporation)
          && !profile.reportPermissions.includes('corporation'),
        create:  ['create', 'write', 'full', 'admin_root'].includes(profile.permissions.corporation),
        write: ['write', 'full', 'admin_root'].includes(profile.permissions.corporation),
        delete:  ['full', 'admin_root'].includes(profile.permissions.corporation),
        admin_root: ['admin_root'].includes(profile.permissions.corporation),
      },
      asf_360: {
        read: ['read',  'create', 'write', 'full', 'admin_root'].includes(profile.permissions.asf_360),
        create: ['create', 'write', 'full', 'admin_root'].includes(profile.permissions.asf_360),
        write: ['write', 'full', 'admin_root'].includes(profile.permissions.asf_360),
        delete: ['full', 'admin_root'].includes(profile.permissions.asf_360),
        admin_root: ['admin_root'].includes(profile.permissions.asf_360)
      },
      receivables: {
        read: ['read',  'create', 'write', 'full', 'admin_root'].includes(profile.permissions.receivables),
        create: ['create', 'write', 'full', 'admin_root'].includes(profile.permissions.receivables),
        write: ['write', 'full', 'admin_root'].includes(profile.permissions.receivables),
        delete: ['full', 'admin_root'].includes(profile.permissions.receivables),
        admin_root: ['admin_root'].includes(profile.permissions.receivables)
      }
    };

    this.userPermission = permissions
    this._permission.next(permissions);
  }

}
