<div class="vertical-navigation-wrapper">

    <!-- Header -->
    <div class="vertical-navigation-header">
        <ng-content select="[verticalNavigationHeader]"></ng-content>
    </div>

    <!-- Content -->
    <div
        class="vertical-navigation-content relative flex flex-col px-[12px] py-[25px]"
        Scrollbar
        [ScrollbarOptions]="{wheelPropagation: inner, suppressScrollX: true}"
        #navigationContent>

        <!-- Content header -->
        <div class="vertical-navigation-content-header">
            <ng-content select="[verticalNavigationContentHeader]"></ng-content>
        </div>

        <!-- Items 1st Section -->
        <ng-container *ngFor="let item of navigation; trackBy: trackByFn">

            <!-- Skip the hidden items -->
            <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">

                <!-- Aside -->
                <ng-container *ngIf="item.type === 'aside'">

                    <vertical-navigation-aside-item
                        [item]="item"
                        [name]="name"
                        [activeItemId]="activeAsideItemId"
                        [autoCollapse]="autoCollapse"
                        [skipChildren]="true"
                        (click)="toggleAside(item)"></vertical-navigation-aside-item>
                </ng-container>

                <!-- Basic -->
                <ng-container *ngIf="item.type === 'basic'">
                    <vertical-navigation-basic-item
                        [item]="item"
                        [name]="name"></vertical-navigation-basic-item>
                </ng-container>

                <!-- Collapsable -->
                <ng-container *ngIf="item.type === 'collapsable'">
                    <vertical-navigation-collapsable-item
                        [item]="item"
                        [name]="name"
                        [autoCollapse]="autoCollapse"></vertical-navigation-collapsable-item>
                </ng-container>

                <!-- Divider -->
                <ng-container *ngIf="item.type === 'divider'">
                    <vertical-navigation-divider-item
                        [item]="item"
                        [name]="name"></vertical-navigation-divider-item>
                </ng-container>

                <!-- Group -->
                <ng-container *ngIf="item.type === 'group' && item.id !== 'settings'" >
                    <vertical-navigation-group-item
                        [class]="offMarginTop ? 'mt-0': '' "
                        [item]="item"
                        [name]="name"
                        [autoCollapse]="autoCollapse"></vertical-navigation-group-item>
                </ng-container>

                <!-- Spacer -->
                <ng-container *ngIf="item.type === 'spacer'">
                    <vertical-navigation-spacer-item
                        [item]="item"
                        [name]="name"></vertical-navigation-spacer-item>
                </ng-container>
            </ng-container>
        </ng-container>
      <div class="flex flex-col items-end justify-end w-full settingsHeight ">
        <!-- Items 2nd Section -->
        <ng-container *ngFor="let item of navigation; trackBy: trackByFn">

          <!-- Skip the hidden items -->
          <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">
            <!-- Group -->
            <ng-container *ngIf="item.id == 'settings'">
              <vertical-navigation-group-item
                class="w-full"
                [item]="item"
              [name]="name"
              [autoCollapse]="autoCollapse">
              </vertical-navigation-group-item>
              <user class="w-full"></user>

            </ng-container>
          </ng-container>

        </ng-container>
      </div>

<!--        &lt;!&ndash; Content footer &ndash;&gt;-->
<!--        <div class="vertical-navigation-content">-->
<!--            <ng-content select="[VerticalNavigationContentFooter]"></ng-content>-->
<!--        </div>-->

    </div>

    <!-- Footer -->
    <div class="vertical-navigation-footer">
        <ng-content select="[VerticalNavigationFooter]"></ng-content>
    </div>

</div>

<!-- Aside -->
<ng-container *ngIf="activeAsideItemId">
    <div
        class="vertical-navigation-aside-wrapper"
        Scrollbar
        [ScrollbarOptions]="{wheelPropagation: false, suppressScrollX: true}"
        [@fadeInLeft]="position === 'left'"
        [@fadeInRight]="position === 'right'"
        [@fadeOutLeft]="position === 'left'"
        [@fadeOutRight]="position === 'right'">

        <!-- Items -->
        <ng-container *ngFor="let item of navigation; trackBy: trackByFn">

            <!-- Skip the hidden items -->
            <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">

                <!-- Aside -->
                <ng-container *ngIf="item.type === 'aside' && item.id === activeAsideItemId">
                    <vertical-navigation-aside-item
                        [item]="item"
                        [name]="name"
                        [autoCollapse]="autoCollapse"></vertical-navigation-aside-item>
                </ng-container>

            </ng-container>

        </ng-container>

    </div>
</ng-container>
