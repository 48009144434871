import { ApplicationConfig } from '@angular/core';
import {PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading} from '@angular/router';

import { routes } from './app.routes';
import {provideAuth} from "./shared/services/auth.provider";
import {CookieService} from "ngx-cookie-service";
import {provideAnimations} from "@angular/platform-browser/animations";
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {provideASF} from "./shared/services/config/asf.provider";
import {provideIcons} from "./shared/services/icons/icons.provider";
import {mockApiServices} from "./mock-api";
import {provideHttpClient, withFetch} from "@angular/common/http";
import {provideEnvironmentNgxMask} from "ngx-mask";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {getPolishPaginationIntl} from "./shared/i18n/polish-paginator";

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideHttpClient(),
    CookieService,
    provideRouter(
      routes,
      withPreloading(PreloadAllModules),
      withInMemoryScrolling({scrollPositionRestoration: 'enabled'}),),
    { provide: MatPaginatorIntl, useValue: getPolishPaginationIntl() },
    provideAuth(),
    provideIcons(),
    provideAnimationsAsync(),
    provideEnvironmentNgxMask(),
    provideASF({
      mockApi: {
        delay   : 0,
        services: mockApiServices,
      },
      config   : {
        layout : 'dense',
        scheme : 'light',
        screens: {
          sm: '600px',
          md: '900px',
          lg: '1280px',
          xl: '1440px',
        },
        theme  : 'theme-default',
        themes : [
          {
            id  : 'theme-default',
            name: 'Default',
          },
          {
            id  : 'theme-brand',
            name: 'Brand',
          },
          {
            id  : 'theme-teal',
            name: 'Teal',
          },
          {
            id  : 'theme-rose',
            name: 'Rose',
          },
          {
            id  : 'theme-purple',
            name: 'Purple',
          },
          {
            id  : 'theme-amber',
            name: 'Amber',
          },
        ],
      },
    }),


  ]
};
