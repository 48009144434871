import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {MatButton} from "@angular/material/button";
import {MatDialog, MatDialogClose, MatDialogRef} from "@angular/material/dialog";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/core";
import {MatSelect} from "@angular/material/select";
import {NgClass, NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {MatMenuItem} from "@angular/material/menu";
import {Observable, Subject, takeUntil} from "rxjs";
import {InsuranceCompanyService} from "../../../services/insurance-company.service";
import {InsuranceCompany} from "../../../interface/insurance-company";
import {environment} from "../../../../../environments/environment.development.360";
import {Scheme} from "../../../config/config.types";
import {UserService} from "../../../services/user/user.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray} from "@angular/cdk/drag-drop";
import {SkeletonComponent} from "../../skeleton/skeleton.component";
import {MatIcon} from "@angular/material/icon";
import {UserErrorDialogComponent} from "../user-error-dialog/user-error-dialog.component";

@Component({
  selector: 'app-user-companies-select-dialog',
  standalone: true,
  imports: [
    FormsModule,
    MatButton,
    MatDialogClose,
    MatFormField,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    NgForOf,
    MatMenuItem,
    NgClass,
    CdkDropList,
    CdkDrag,
    MatIcon,
    SkeletonComponent,
    NgIf,
    NgOptimizedImage,
  ],
  templateUrl: './user-companies-select-dialog.component.html',
  styleUrl: './user-companies-select-dialog.component.scss'
})
export class UserCompaniesSelectDialogComponent implements OnInit{
  readonly String = String;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  error: string;
  isDarkMode: boolean;

  insuranceCompanys$: Observable<InsuranceCompany[]>;
  insuranceCompanys: InsuranceCompany[];
  selected = [];
  loadedImgs = [];
  constructor (
  private _insuranceService: InsuranceCompanyService,
  private _changeDetectorRef: ChangeDetectorRef,
  private _userService: UserService,
  private _matDialog: MatDialog,

  ){}

  ngOnInit(): void {

    this._userService.isDarkMode$
      .pipe()
      .subscribe((isDarkMode: boolean) => {
        const scheme: Scheme = isDarkMode ? 'dark' : 'light';
        this.isDarkMode = scheme === 'dark';
      })

    this.insuranceCompanys$ = this._insuranceService.activeInsuranceCompanys$
    this.insuranceCompanys$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((insuranceCompany) => {
        this.insuranceCompanys = insuranceCompany;
      });


    const userData = localStorage.getItem('user');
    const user = JSON.parse(userData);

    this.insuranceCompanys = this.insuranceCompanys.filter(item1 =>
      user.permissionRegistration.some(item2 => item2 === item1.id && !item1.inactive && item1.link)    //sprawdzam id z permissionRegistration oraz status aktywna oraz czy ma link
    );

    this.insuranceCompanys = this.insuranceCompanys.filter((item, index, array) => {
      return !array.some((otherItem, otherIndex) => otherIndex < index && otherItem.id === item.id);
    });

    this.initialSelection();
    this._changeDetectorRef.detectChanges();
  }

  selectItem(item: any) {
    this.isSelected(item);
    if (this.selected?.includes(String(item.id))) {
      this.selected = this.selected.filter(i => i !== String(item.id));
      this.error = "";
    } else {
      if (this.selected.length < 8) {
        this.selected.push(String(item.id));
        } else {
        this.error = "Sekcja ulubionych TU jest pełna";
        this._matDialog.open(UserErrorDialogComponent);
      }
    }
    this.setFavorites();
  }
  removeFromSelected (id: string){
    if (this.selected?.includes(String(id))) {
      this.selected = this.selected.filter(i => i !== String(id));
      this.setFavorites();
    }
  }


  isSelected(item) {
    return this.selected.includes(String(item.id));
  }

  setFavorites(): void
  {
    let item = this.selected;
    this._userService.editUserSetting(item).pipe().subscribe();
  }

  initialSelection(): void{
    const stored = localStorage.getItem('favoriteCompanies');
    if(stored == ''){
      return;
    } else {
      this.selected = stored.split(',').map(String);
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selected, event.previousIndex, event.currentIndex);
    this.setFavorites();
    this._changeDetectorRef.detectChanges();
  }


  imageLoaded(id: any): void{
    this.loadedImgs.push(id);
  }


  insuranceCompanyKeyFinder(id: any): string | undefined {
    const foundItem = this.insuranceCompanys.find(item => item.id.toString() === id);
    if(foundItem){
      return foundItem.key;
    }
    return null;
  }


  trackByFn(index: number, item: any) {
    return item.id; // Assuming each item has a unique id
  }
  insuranceCompanyNameFinder(id: any): string | undefined {
    const foundItem = this.insuranceCompanys.find(item => item.id.toString() === id);
    if(foundItem){
      return foundItem.fullName;
    }
    return null;
  }
}
