import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, inject, Injectable} from '@angular/core';
import {catchError, first, map, Observable, of, ReplaySubject, switchMap, take, tap} from 'rxjs';
import {User} from "./user.types";
import {environment} from "../../../../environments/environment";
import {AuthService} from "../auth.service";
import {Profile} from "../../interface/profile";
import {DOCUMENT} from "@angular/common";
import {Uuid} from "../../interface/response/uuid";
import {RegistrationService} from "../registration.service";

@Injectable({providedIn: 'root'})
export class UserService {

  private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
  private _darkMode: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _dropdownMenu: ReplaySubject<any> = new ReplaySubject<boolean>(1);
  private _isDarkModeSubject: ReplaySubject<any> = new ReplaySubject<boolean>(1);
  private _isDropDownMenuSubject: ReplaySubject<any> = new ReplaySubject<boolean>(1);
  private _isFavoriteCompaniesSubject: ReplaySubject<any> = new ReplaySubject<boolean>(1);
  isDarkMode$ = this._isDarkModeSubject.asObservable()
  isDropDownMenu$ = this._isDropDownMenuSubject.asObservable()
  _isFavoriteCompanies$ = this._isFavoriteCompaniesSubject.asObservable()

  private storageSchemeKey = 'scheme'
  private storageDropDownMenuKey = 'dropdownMenu'
  private storageFavoriteCompanies = 'favoriteCompanies'

  constructor(
    private _httpClient: HttpClient,
    @Inject(DOCUMENT) private _document: Document,
  ) {
  }

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: User) {
    // Store the value
    this._user.next(value);
  }

  get user$(): Observable<User> {
    return this._user.asObservable();
  }

  set scheme(value: string) {
    localStorage.setItem(this.storageSchemeKey, value);
  }

  get scheme(): string {
    return localStorage.getItem(this.storageSchemeKey)
  }

  set dropdownMenu(value: string) {
    localStorage.setItem(this.storageDropDownMenuKey, value);
  }


  get dropdownMenu(): string {
    return localStorage.getItem(this.storageDropDownMenuKey)
  }

  set favoriteCompanies(value: string) {
    localStorage.setItem(this.storageFavoriteCompanies, value);
  }


  get favoriteCompanies(): string {
    return localStorage.getItem(this.storageFavoriteCompanies)
  }

  setLight(): void {
    this.scheme = 'light';
    this._isDarkModeSubject.next(false)
  }

  setDark(): void {
    this.scheme = 'dark'
    this._isDarkModeSubject.next(true)
  }

  setDropDownMenuDense(): void {
    this.dropdownMenu = 'dense'
    this._isDropDownMenuSubject.next(true)
  }

  setDropDownMenuDefault(): void {
    this.dropdownMenu = 'default'
    this._isDropDownMenuSubject.next(false)
  }

  setFavoriteCompanies($val): void {
    this.favoriteCompanies = $val
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current signed-in user data
   */
  getUser(): Observable<User> {
    return this._httpClient.get(`/rest/crm/user/${localStorage.getItem('userUuid')}`)
      .pipe(
        catchError((err, caught) =>
          // Return false
          of(err),
        ),
        first(),
        tap((user) => {
          this._user.next(<User>user);
          if (user.userSetting !== null) {
            user.userSetting.darkMode ? this.setDark() : this.setLight();
            user.userSetting.dropdownMenu ? this.setDropDownMenuDense() : this.setDropDownMenuDefault();
            this.setFavoriteCompanies(user.userSetting.favoriteCompanies)

          } else {
            localStorage.setItem(this.storageSchemeKey, 'light');
            localStorage.setItem(this.storageDropDownMenuKey, 'default');
            this._isDarkModeSubject.next(false);
            this._isDropDownMenuSubject.next(false);
          }
          this.editUserSetting().pipe().subscribe();

        })
      ) as Observable<User>;
  }

  editUserSetting(item?: any): Observable<Uuid> {
if (item){
  return this._httpClient.put(`/rest/crm/user-setting/${localStorage.getItem('userUuid')}`,
    {
      darkMode: this.scheme == 'dark',
      dropdownMenu: this.dropdownMenu == 'dense',
      favoriteCompanies: this.favoriteCompanies = item
    })
    .pipe(first()) as Observable<Uuid>;
  }
else {
  const str = localStorage.getItem('favoriteCompanies');
  const favs = str ? str.split(',') : [];
  return this._httpClient.put(`/rest/crm/user-setting/${localStorage.getItem('userUuid')}`,
    {
      darkMode: this.scheme == 'dark',
      dropdownMenu: this.dropdownMenu == 'dense',
      favoriteCompanies: favs
    })
    .pipe(first()) as Observable<Uuid>;
}

}
}
