import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, map, Observable, of, ReplaySubject, switchMap, take, tap, throwError} from "rxjs";
import {Registration} from "../interface/registration";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {FilterService} from "./filter-service.service";
import {DOCUMENT} from "@angular/common";
import {Pagination} from "../interface/response/pagination";
import {InsuranceCompanyService} from "./insurance-company.service";
import {ListResponse} from "../interface/response/list-response";
import {Document as DocumentInterface} from "../interface/document";
import {COMPANY_ASF_PREMIUM} from "./dict.service";

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  private _pagination: BehaviorSubject<Pagination | null> = new BehaviorSubject(null);
  private _workerDocuments: BehaviorSubject<DocumentInterface[] | null> = new BehaviorSubject(null);
  private _registration: BehaviorSubject<Registration | null> = new BehaviorSubject(null );
  private _registrations: BehaviorSubject<Registration[] | null> = new BehaviorSubject(null );
  private _registrationsLogins: ReplaySubject<any | null> = new ReplaySubject(1);
  private _registrationsOtherThanRegistered: ReplaySubject<any | null> = new ReplaySubject(1);
  private _registrationsFiltered: BehaviorSubject<Registration[] | null> = new BehaviorSubject(null );


  constructor(
    private _httpClient: HttpClient,
    private _filterService: FilterService,
    @Inject(DOCUMENT) private _document: Document,
    private _insuranceCompanyService: InsuranceCompanyService,
  ) { }

  /**
   * Getter for pagination
   */
  get pagination$(): Observable<Pagination> {
    return this._pagination.asObservable();
  }

  get registration$(): Observable<Registration | null> {
    return this._registration.asObservable();
  }
  get registrationLogin$(): Observable<any[] | null> {
    return this._registrationsLogins.asObservable();
  }
  get registrationsOtherThanRegistered$(): Observable<any[] | null> {
    return this._registrationsOtherThanRegistered.asObservable();
  }

  get registrations$(): Observable<Registration[] | null> {
    return this._registrations.asObservable();
  }
  get registrationsFiltered$(): Observable<Registration[] | null> {
    return this._registrationsFiltered.asObservable();
  }


  get ofwcaRegistrationsDocuments$(): Observable<DocumentInterface[]> {
    return this._workerDocuments.asObservable();
  }

  clearRegistrations() {
    this._pagination.next(null);
    this._registrations.next([]);
  }
  /**
   *
   * @param filters
   * @param page
   * @param size
   * @param sort
   * @param order
   */
  getRegistrations(
    filters: object,
    page: number = 0,
    size: number = 10,
    sort: string = 'insuranceCompany',
    order: 'asc' | 'desc' | '' = 'asc',
  ): Observable<{
    pagination: Pagination;
    items: Registration[];
  }> {
    return this._httpClient
      .get<{pagination: Pagination; items: Registration[]}>(`/rest/crm/asf24/registration?page=${page + 1}&pageSize=${size}&sortBy=${sort}&sortOrder=${order}&`
        + this._filterService.parseFilters(filters))
      .pipe(
        tap((response: {pagination: Pagination, items: Registration[]}) => {
          this._pagination.next(response.pagination);
          this._registrations.next(response.items);
        })
      );
  }
  // getRegistrations(
  //   filters: object,
  //   page: number = 0,
  //   size: number = 10,
  //   sort: string = 'insuranceCompany',
  //   order: 'asc' | 'desc' | '' = 'asc',
  // ): Observable<{
  //   pagination: Pagination;
  //   items: Registration[];
  // }> {
  //   return this._httpClient
  //     .get<{pagination: Pagination; items: Registration[]}>(this._apiUrl + `/rest/crm/asf360/registration?page=${page + 1}&pageSize=${size}&sortBy=${sort}&sortOrder=${order}&`
  //       + this.filterService.parseFilters(filters))
  //     .pipe(
  //       tap((response) => {
  //         this._pagination.next(response.pagination);
  //         this._registrations.next(response.items);
  //       })
  //     );
  // }

  /**
   *
   * @param filters
   * @param page
   * @param size
   * @param sort
   * @param order
   */
  getRegistrationsFiltered(
    filters: object,
    page: number = 0,
    size: number = 10,
    sort: string = 'insuranceCompany',
    order: 'asc' | 'desc' | '' = 'asc',
  ): Observable<{
    items: Registration[];
  }> {
    return this._httpClient
      .get<{items: Registration[]}>(`/rest/crm/asf24/registration?page=${page + 1}&pageSize=${size}&sortBy=${sort}&sortOrder=${order}&`
        + this._filterService.parseFilters(filters))
      .pipe(
        tap((response: { items: Registration[]}) => {
          this._registrationsFiltered.next(response.items);
        })
      );
  }

  clearRegistration(){
    this._registration.next(null);
  }

  /**
   * Get registration by uuid
   */
  getRegistrationById(uuid: string): Observable<any> {
    return this._registrations.pipe(
      take(1),
      map((registrations) => {
        const registration = registrations.find((item) => item.uuid === uuid) || null;
        // Update the mail
        this._registration.next(registration);
        this._insuranceCompanyService.clearLogo();
        this._insuranceCompanyService.transform(registration?.insuranceCompany?.id).then(() => true)

        // Return the mail
        return registration;
      }),
      switchMap((registration) => {
        if (!registration) {
          return throwError(
            'Brak rejestracji' + uuid + '!'
          );
        }

        return of(registration);
      })
    );
  }
  getRegistrationDocumentsList(ofwcaId: string, insuranceCompanyId: string): Observable<ListResponse<DocumentInterface>> {
    return this._httpClient.get<ListResponse<DocumentInterface>>(`/rest/crm/asf24/ofwca/${ofwcaId}/documents/${insuranceCompanyId}`).pipe(
      tap((response) => {
        this._workerDocuments.next(response.items);
      })
    );
  }


  getRegistrationsLoginsByOfwcaUuid(
  ): Observable<any> {

    return this._httpClient
      .get<any>(`/rest/crm/asf24/ofwca/registration/insuranceCompany/`
        + localStorage.getItem('ofwcaUuid'))
      .pipe(
        take(1),
        tap((response) => {
          this._registrationsLogins.next(response);
        })
      );
  }

  getOfwcaRegistrationsOtherThanRegistered(
  ): Observable<any> {

    return this._httpClient
      .get<any>(`/rest/crm/asf24/ofwca/registration/insuranceCompany/notregistered/`
        + localStorage.getItem('ofwcaUuid'))
      .pipe(
        take(1),
        tap((response) => {
          this._registrationsOtherThanRegistered.next(response);
        })
      );
  }


}
