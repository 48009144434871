import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDivider} from "@angular/material/divider";
import {MatIcon} from "@angular/material/icon";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatTooltip} from "@angular/material/tooltip";
import {Observable, Subject, takeUntil} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {ImageComponent} from "../../image/image.component";
import {JsonPipe, NgIf} from "@angular/common";
import {User} from "../../../services/user/user.types";
import {UserService} from "../../../services/user/user.service";
import {AuthService} from "../../../services/auth.service";
import {MatGridList} from "@angular/material/grid-list";
import {MatButton} from "@angular/material/button";
import {SubmissionsAddDialogComponent} from "../../submissions/submissions-add-dialog/submissions-add-dialog.component";
import {
  UserCompaniesSelectDialogComponent
} from "../user-companies-select-dialog/user-companies-select-dialog.component";
import {InsuranceCompany} from "../../../interface/insurance-company";
import {InsuranceCompanyService} from "../../../services/insurance-company.service";
import {environment} from "../../../../../environments/environment.development.360";
import {Scheme} from "../../../config/config.types";
import {Config, ConfigService} from "../../../services/config/config";
import {UserAllCompaniesDialogComponent} from "../user-all-companies-dialog/user-all-companies-dialog.component";
import {RegistrationService} from "../../../services/registration.service";

@Component({
  selector: 'app-user-navigation',
  standalone: true,
  imports: [
    MatDivider,
    MatIcon,
    MatMenuItem,
    MatTooltip,
    ImageComponent,
    MatMenu,
    NgIf,
    MatMenuTrigger,
    MatGridList,
    MatButton,
    JsonPipe
  ],
  templateUrl: './user-navigation.component.html',
  styleUrl: './user-navigation.component.scss'
})
export class UserNavigationComponent implements OnInit, OnDestroy{
  @Input() iconHeader: boolean = true;
  config: Config;
  user: User;
  isModalOpen = false;
  adminRole: boolean = false;
  ofwcaUuid: boolean = false;
  favoriteCompanies = [];
  insuranceCompanys2$: Observable<InsuranceCompany[]>;
  restService: Observable<InsuranceCompany[]>;
  insuranceCompanys2: InsuranceCompany[];
  insuranceCompanys: InsuranceCompany[];
  matchingCompanies: InsuranceCompany[];
   mergedArray: any[];
  isDarkMode: boolean;

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  favoriteCompaniesChecker = [];

  insuranceCompanies = [
    // {title: 'asf24', link: 'https://asf24.pl/', photo: 'assets/images/brands/asf24/asf24.png' , photodark: 'assets/images/brands/asf24/asf24-dark.png'},
    {title: 'Hiperio', link: 'https://sso.ubezpieczenia.asfpremium.pl/', photo: 'assets/images/brands/Hiperio/Hiperio2.png' , photodark: 'assets/images/brands/Hiperio/Hiperio-dark.png'},
    {title: 'Chronie.pl', link: 'https://chronie.pl/', photo: 'assets/images/brands/Chronie/Chronie.png' , photodark: 'assets/images/brands/Chronie/Chronie-dark.png'},
    {title: 'DobryAgent', link: 'https://dobryagent.pl/', photo: 'assets/images/brands/DobryAgent/DobryAgent.png' , photodark: 'assets/images/brands/DobryAgent/DobryAgent-dark.png'},
  ];

  constructor(
    private _matDialog: MatDialog,
    private _authService: AuthService,
    private _userService: UserService,
    private _insuranceService: InsuranceCompanyService,
    private _registrationService: RegistrationService,
    private _changeDetectorRef: ChangeDetectorRef,
) {
  }

  /**
   * On init
   */
  ngOnInit(): void {

    this.user = this._authService.user;
    this.user.roles.forEach((response) => {
      if(response == 'ROLE_ADMIN'){
        this.adminRole = true;
      }
    });
    if (localStorage.getItem('ofwcaUuid'))
    {
      this.ofwcaUuid = true;
    }
    this.favoriteCompaniesChecker = this._userService.favoriteCompanies.split(',').map(Number);

   this._registrationService.getOfwcaRegistrationsOtherThanRegistered().subscribe(value => {});
   this._insuranceService.loadActiveInsuranceCompanys().subscribe(value => {});
   this._insuranceService.loadInsuranceCompanys().subscribe(value => {});


    this._userService.isDarkMode$
      .pipe()
      .subscribe((isDarkMode: boolean) => {
        const scheme: Scheme = isDarkMode ? 'dark' : 'light';
        this.isDarkMode = scheme === 'dark';
      });

    this.insuranceCompanys2$ = this._insuranceService.activeInsuranceCompanys$
    this.insuranceCompanys2$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((insuranceCompany) => {
        this.insuranceCompanys2 = insuranceCompany;
      });

    this.favoriteCompaniesChecker = this.favoriteCompaniesChecker.filter(item => {
      return this.insuranceCompanys2.find(i => i.id === item);
    });

    this._userService.setFavoriteCompanies(this.favoriteCompaniesChecker);
    this._userService.editUserSetting().pipe().subscribe((result) => {
    });


    this.loadIcons();
    this._changeDetectorRef.detectChanges();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  showAllCompanies() {
    this.isModalOpen = true;
    const dialogRef = this._matDialog.open(UserAllCompaniesDialogComponent, {
      autoFocus: false,
      maxHeight: '75vh',
      minHeight: '75vh',
      panelClass: 'all-companies-dialog',
      closeOnNavigation: true,
    })


    dialogRef.afterClosed().subscribe(val => {
    })
  }



  addCompanyToFavorites() {

    this.isModalOpen = true;
    const dialogRef = this._matDialog.open(UserCompaniesSelectDialogComponent, {
      autoFocus: false,
      closeOnNavigation: true,
      maxHeight: '60vh',
      minHeight: '60vh',
      maxWidth: '900px',
      width: '90vw',
      panelClass: 'select-companies-dialog'
    })

    dialogRef.afterClosed().subscribe(val => {
      this.loadIcons();
      this._changeDetectorRef.detectChanges();
    })
  }

  loadIcons(): void{
    const stored = localStorage.getItem('favoriteCompanies');
    this.favoriteCompanies = stored?.split(',').map(String);
    if(this.favoriteCompanies.length > 0) {
      this.matchingCompanies = this.insuranceCompanys2.filter(company => this.favoriteCompanies.includes(String(company.id)))
        .sort((a, b) => {
          const aIndex = this.favoriteCompanies.indexOf(String(a.id));
          const bIndex = this.favoriteCompanies.indexOf(String(b.id));
          return aIndex - bIndex;
        });
      this.mergedArray = this.matchingCompanies.map(item1 => {
        const item2 = this.insuranceCompanys2?.find(item => item.id === item1.id);
        return { ...item1, ...item2 };
      });
      this.matchingCompanies = this.mergedArray;
      const uniqueCompanies = this.matchingCompanies.filter((value, index, array) => {
        return array.findIndex(item => item.id === value.id) === index;
      });
      this.matchingCompanies = uniqueCompanies;
    }
  }

}
