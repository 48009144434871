import { Component } from '@angular/core';
import {MatDialogActions, MatDialogContent} from "@angular/material/dialog";

@Component({
  selector: 'app-user-error-dialog',
  standalone: true,
  imports: [
    MatDialogActions,
    MatDialogContent
  ],
  templateUrl: './user-error-dialog.component.html',
  styleUrl: './user-error-dialog.component.scss'
})
export class UserErrorDialogComponent {
  duration: number = 2;
}
