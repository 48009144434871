<div class="grid grid-cols-1 gap-3 justify-items-start">
  @if (ofwcaUuid) {
    <div class="flex items-center p-3  ring-inset ring-primary cursor-pointer  relative group "
         [matTooltip]="'Menu Użytkownika'" [matMenuTriggerFor]="menu">
      <div class="flex items-center overflow-hidden ">
        <button class="navigation-user-avatar ">
          <mat-icon
            class="icon-size-5"
            [svgIcon]="'heroicons_outline:squares-plus'">
          </mat-icon>
        </button>
      </div>
    </div>
  }
</div>
<mat-menu #menu="matMenu" [xPosition]="'after'" [yPosition]="'below'" backdropClass="customize2" class="w-full">
  <ng-container>
    <div class="flex-row-container  bg-white dark:bg-asf360Shark-900 z-20 p-3">
      @for (item of insuranceCompanies; track item; ) {
        <div class="flex-row-item flex flex-col text-center">
          <div class="  w-full">
            <div class=" flex svg-container">
              <a [href]="item.link" target="_blank" class="flex justify-center w-full">
                <img class="w-14 h-10 object-contain"
                     *ngIf="!isDarkMode" src="{{item.photo}}" alt="{{item.title}}">
                <img class="w-14 h-10 object-contain"
                     *ngIf="isDarkMode" src="{{item.photodark}}" alt="{{item.title}}">
              </a>

            </div>
          </div>
          <div class="text-center flex w-full justify-center ">
    <span class="truncate-text text-black dark:text-white mt-2 mr-1" title="{{item.title}}">
      {{ item.title }}
    </span>
          </div>
        </div>
      }
      @for (item of matchingCompanies; track item; ) {
        <div class="flex-row-item flex flex-col text-center">
          <div class=" items-center flex" title="{{item.link}}">
            <a [href]="item.link" target="_blank" class="flex justify-center w-full">
              <img
                class="w-16 h-12 object-contain"
                *ngIf="!isDarkMode"
                src="assets/images/insurancecompanies/{{item?.key}}/{{item?.key}}.png"
                alt="Zdjęcie">
              <img
                class="w-16 h-12 object-contain"
                *ngIf="isDarkMode"
                src="assets/images/insurancecompanies/{{item?.key}}/{{item?.key}}-dark.png"
                alt="Zdjęcie">
            </a>
          </div>
          <div class="text-center flex w-full justify-center ">
    <span class="truncate-text  dark:text-white text-black mt-2 mr-1">
  {{ item.fullName }}
    </span>
          </div>
        </div>
      }
    </div>
  </ng-container>

  <ng-container>
    <div class="footer flex flex-col w-full border-t-gray-100 border-t bg:white z-10 p-3 ">
      <div class="flex flex-row items-center justify-stretch cursor-pointer" (click)="showAllCompanies()">
        <div class="px-2 items-center justify-center">
          <mat-icon class="icon-size-4 mt-1.5" [svgIcon]="'heroicons_outline:list-bullet'"></mat-icon>
        </div>
        <span> Wyświetl wszystkie TU </span>
      </div>
      <div class="flex flex-row items-center justify-stretch cursor-pointer" (click)="addCompanyToFavorites()">
        <div class="px-2 items-center justify-center">
          <mat-icon class="icon-size-4 mt-1.5" [svgIcon]="'heroicons_outline:star'"></mat-icon>
        </div>
        <span> Dodaj TU do ulubionych </span>
      </div>
    </div>
  </ng-container>
</mat-menu>
