import {Route, Routes} from '@angular/router';
import {LayoutComponent} from "./layout/layout.component";
import {AuthGuard} from "./shared/services/guards/auth.guard";
import {initialDataResolver} from "./app.resolvers";
import {NoAuthGuard} from "./shared/services/guards/noAuth.guard";
import {inject} from "@angular/core";
import {DictService} from "./shared/services/dict.service";
import {AuthService} from "./shared/services/auth.service";
import {AuthPermissionGuard} from "./shared/services/guards/authPermission.guard";
import {UserService} from "./shared/services/user/user.service";
import {InsuranceCompanyService} from "./shared/services/insurance-company.service";
import {RegistrationService} from "./shared/services/registration.service";

export const routes: Route[] = [
  {path: '', pathMatch : 'full', redirectTo: 'home'},

  {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'home'},

  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [
      {path: 'sign-in', loadChildren: () => import('./modules/auth/sign-in/sign-in.routes')},
    ]
  },

// AuthGuard
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'dense'
    },
    resolve: {
      initialData: initialDataResolver,
    },
    children: [
      {
        path: '',
        resolve:{
          dictionary: () => inject(DictService).loadDict(),
          user: () => inject(UserService).getUser(),
          activeInsuranceCompany: () => inject(InsuranceCompanyService).loadActiveInsuranceCompanys(),
          insuranceCompanys: () => inject(InsuranceCompanyService).loadInsuranceCompanys(),
        },
        children: [
          {path: 'home', loadChildren: () => import('./modules/home/home.routes')},
          {path: 'rozliczenia', loadChildren: () => import('./modules/settlements/settlements.routes')},
          {path: 'sprzedaz', loadChildren: () => import('./modules/sales/sales.routes')},
          {path: 'naleznosci', loadChildren: () => import('./modules/receivables/receivables.routes')},
          {path: 'pracownicy', loadChildren: () => import('./modules/workers/workers.routes')},
          {path: 'umowy', loadChildren: () => import('./modules/contracts/contracts.routes')},
          {path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/error/error-404/error-404.routes')},
        ]
      },
      {path: '**', redirectTo: '404-not-found'}
    ]
  },


];
