<!-- Loading bar -->
<loading-bar></loading-bar>

<!-- Navigation -->
<vertical-navigation
  class="bg-asf360Primary-50 dark:bg-asf360Shark-800 print:hidden"
  [appearance]="navigationAppearance"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation?.default"
  [opened]="!isScreenSmall"
  [offMarginTop]="true"
>
  <!-- Navigation header hook -->
  <ng-container verticalNavigationHeader>
<div class="w-full">
    @if (navigationAppearance !== 'dense'){
    <div class="flex items-center h-[56px]  justify-start border-b mx-2 border-asf360Primary-200 dark:border-asf360Shark-500 cursor-pointer" routerLink="/home">
        <img
          *ngIf="!isDarkMode" class="justify-start flex w-auto  max-h-[22px]"
          src="assets/images/logo/logo-text.svg"
          alt="Logo image">
      <img
        *ngIf="isDarkMode"
        class="justify-start flex w-auto   max-h-[22px]"
        src="assets/images/logo/logo-text-on-dark.svg"
        alt="Logo image">
    </div>
    } @else {
      <div class="flex items-center h-[56px]  justify-start cursor-pointer border-b mx-3 border-asf360Primary-200 dark:border-asf360Shark-500" routerLink="/home">
          <img
            *ngIf="!isDarkMode" class=" max-w-26 w-full max-h-[22px]"
            src="assets/images/logo/logo-text.svg"
            alt="Logo image">
        <img
            *ngIf="isDarkMode" class=" max-w-26 w-full max-h-[22px]"
            src="assets/images/logo/logo-text-on-dark.svg"
            alt="Logo image">
      </div>
    }
</div>
  </ng-container>

<!--  <ng-container VerticalNavigationContentFooter >-->
<!--        <user class="w-full" [appearence]="navigationAppearance"></user>-->
<!--  </ng-container>-->
<!--  <ng-container verticalNavigationContentHeader>-->
<!--    <div class="flex items-center justify-center h-20">-->
<!--    </div>-->
<!--  </ng-container>-->

</vertical-navigation>




<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0 ">
    <!-- Header -->
    <div class="relative flex flex-0 items-center  h-[56px] w-full z-10 dark:bg-asf360Shark-900 bg-card print:hidden">
      <div class=" w-full flex border-b h-[56px]  dark:bg-asf360Shark-900 dark:border-asf360Shark-600 border-asf360Shark-50 mx-2">
      <div class="flex items-center space-x-2">
            <!-- Navigation toggle button -->
            <button
              class="inline-flex md:hidden"
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
            </button>
            <!-- Navigation appearance toggle button -->
            <button  [matTooltip]="navigationAppearance == 'dense' ? 'Rowziń menu' : 'Zwiń menu'"
                     [matTooltipClass]="'toolTipTest'"
                class="hidden md:inline-flex"
                mat-icon-button
                (click)="toggleNavigationAppearance()" >
                <mat-icon [svgIcon]="'heroicons_outline:arrows-right-left'"></mat-icon>
            </button>
        </div>
        <!-- Components -->
        <div  class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
<!--            <search [appearance]="'bar'"></search>-->
<!--            <shortcuts></shortcuts>-->
<!--            <messages></messages>-->
<!--            <notifications></notifications>-->
<!--            <button-->
<!--                class="lg:hidden"-->
<!--                mat-icon-button-->
<!--                (click)="quickChat.toggle()">-->
<!--                <mat-icon [svgIcon]="'heroicons_outline:chat-bubble-left-right'"></mat-icon>-->
<!--            </button>-->
          <asf-fullscreen class="hidden md:block"></asf-fullscreen>
<!--          <app-submissions-add></app-submissions-add>-->
          <app-settings-navigation></app-settings-navigation>
          <app-user-navigation></app-user-navigation>
          <!--          <user class="flex"></user>-->
        </div>
      </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
<!--    <div class="relative flex flex-0 items-center justify-start w-full px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden pt-4 md:pb-4 pb-24 ">-->
<!--        <span class="font-medium text-secondary">ASF360 &copy; {{currentYear}}</span>-->
<!--    </div>-->
<!--  <mobile-navigation-->
<!--    *ngIf="isScreenSmall"-->
<!--    [name]="'mobileNavigation'"-->
<!--    [navigation]="navigation.mobile"-->
<!--    (activeToggle)="toggleNavigation($event)"-->
<!--  ></mobile-navigation>-->

</div>

<!-- Quick chat -->
<!--<quick-chat #quickChat="quickChat"></quick-chat>-->
