<div class=" flex flex-row  px-[8px]">
  <div class=" flex  items-center      flex-row w-full hover:cursor-pointer "  [matMenuTriggerFor]="userActions">
    <div class="userAvatar">
      <button class="navigation-user-avatar" (click)="getProfile()"
              [matTooltip]="user ? user.firstName + ' ' + user.lastName : ''">
        <app-image [user]="user" [class]="'w-[32px] h-[32px]'"></app-image>
      </button>
    </div>
    <div class="flex flex-row w-full items-center userText ">
      <div class=" flex justify-center w-1/2 ">
        <span class="text-[14px] font-[400] text-shark-900 dark:text-white  ">{{ user?.firstName }}</span>
      </div>
      <div class="justify-end w-1/2  flex ">
        <mat-icon [svgIcon]="'heroicons_outline:chevron-up-down'"></mat-icon>
      </div>
    </div>
  </div>
</div>
<mat-menu
  class="dense-test"
  [yPosition]="'below'"
  #userActions="matMenu">
  <button mat-menu-item>
          <span class="flex flex-col leading-none">
              <span class="mt-1.5  font-bold">{{ user?.firstName }} {{ user?.lastName }}</span>
              <span class="mt-1.5 text-xs font-medium">{{ user?.email }}</span>
          </span>
  </button>

  <ng-container *ngIf="adminRole">
    <mat-divider class="my-2"></mat-divider>
    <a mat-menu-item [href]="apiUrl" target="_blank">
      <mat-icon
        class="icon-size-5"
        [svgIcon]="'heroicons_outline:asf-premium-signet'"></mat-icon>
      <span>asf24.pl</span></a>
  </ng-container>
  <ng-container>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="addSubmission()">
      <mat-icon
        class="icon-size-5"
        [svgIcon]="'heroicons_outline:megaphone'"></mat-icon>
      <span>Zgłoś zmianę</span></button>
  </ng-container>
  <mat-divider class="my-2"></mat-divider>
  <button
    mat-menu-item
    (click)="signOut()">
    <mat-icon
      class="icon-size-5"
      [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
    <span class=" text-black dark:text-white">Wyloguj się</span>
  </button>
</mat-menu>
