import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  constructor() { }

  public parseFilters(filters: object): string {
    let resultString = '';
    let filterValue = '';

    for (const [key, value] of Object.entries(filters)) {
      if (value === null || value === '' || (Array.isArray(value) && value.length === 0)) {
        continue;
      }

      if (resultString !== '') {
        resultString += '&';
      }

      if (moment.isMoment(value)) {
        filterValue = value.format('YYYY-MM-DD');
      } else {
        filterValue = value === value + '' ? value.trim() : value;
      }

      resultString += 'filter[' + encodeURIComponent(key) + ']=' + encodeURIComponent(filterValue);
    }

    return resultString;
  }

  public parseFiltersToObject(filters: object): object {
    const resultObject = {};
    let filterValue = '';

    for (const [key, value] of Object.entries(filters)) {
      if (value === null || value === '' || (Array.isArray(value) && value.length === 0)) {
        continue;
      }

      if (moment.isMoment(value)) {
        filterValue = value.format('YYYY-MM-DD');
      } else {
        filterValue = value === value + '' ? value.trim() : value;
      }

      resultObject[key] = filterValue;
    }

    return resultObject;
  }

  public parseFiltersFromQueryParams(types: {[key: string]: string}, filters?: any, ): any {
    const result = {};

    for (const [fieldName, fieldType] of Object.entries(types)) {
      if (!filters || !filters.hasOwnProperty(fieldName)) {
        result[fieldName] = fieldType.includes('[]') ? [] : '';
        continue;
      }

      const val = filters[fieldName];

      if (fieldType === 'string') {
        result[fieldName] = val;
        continue;
      }

      if (fieldType === 'int[]') {
        result[fieldName] =  val ? val.split(',').map((v) => parseInt(v, 10)) : '';
        continue;
      }

      if (fieldType === 'int') {
        result[fieldName] = val ? parseInt(val, 10) : '';
        continue;
      }

      if (fieldType === 'string[]') {
        result[fieldName] = val ? val.split(',') : '';
        continue;
      }

      if (fieldType === 'date') {
        result[fieldName] = val ? moment(val) : '';
        continue;
      }
    }

    return result;
  }

  parseFiltersToQueryParams(filters: object): any {
    const resultObject = {};
    let filterValue = '';

    for (const [key, value] of Object.entries(filters)) {
      if (value === null || value === '' || (Array.isArray(value) && value.length === 0)) {
        continue;
      }

      if (moment.isMoment(value)) {
        filterValue = value.format('YYYY-MM-DD');
      } else {
        filterValue = value === value + '' ? value.trim() : value;
      }

      resultObject[key] = filterValue.toString();
    }

    return resultObject;
  }

  public parseSelects(selects: object): string {
    let resultString = '';
    let filterValue = '';

    for (const [key, value] of Object.entries(selects)) {
      if (value === null || value === '' || (Array.isArray(value) && value.length === 0)) {
        continue;
      }

      if (resultString !== '') {
        resultString += '&';
      }

      if (moment.isMoment(value)) {
        filterValue = value.format('YYYY-MM-DD');
      } else {
        filterValue = value === value + '' ? value.trim() : value;
      }

      resultString += 'select[' + encodeURIComponent(key) + ']=' + encodeURIComponent(filterValue);
    }

    return resultString;
  }

}
