import { BooleanInput } from '@angular/cdk/coercion';
import {JsonPipe, NgClass, NgIf, NgTemplateOutlet} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit, Renderer2,
  ViewEncapsulation
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import {User} from "../../../shared/services/user/user.types";
import {UserService} from "../../../shared/services/user/user.service";
import {AuthService} from "../../../shared/services/auth.service";
import {environment} from "../../../../environments/environment";
import {MatTooltip} from "@angular/material/tooltip";
import {ImageComponent} from "../../../shared/components/image/image.component";
import {Subject} from "rxjs";
import {
  SubmissionsAddComponent
} from "../../../shared/components/submissions/submissions-add/submissions-add.component";
import {
  SubmissionsAddDialogComponent
} from "../../../shared/components/submissions/submissions-add-dialog/submissions-add-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {NavigationItem} from "../../../shared/components/navigation";
import {
  SettingsNavigationComponent
} from "../../../shared/components/settings/settings-navigation/settings-navigation.component";

@Component({
    selector       : 'user',
    templateUrl    : './user.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'user',
    standalone     : true,
  imports: [MatButtonModule, MatMenuModule, NgIf, MatIconModule, NgClass, MatDividerModule, JsonPipe, NgTemplateOutlet, MatTooltip, ImageComponent, SubmissionsAddComponent, SettingsNavigationComponent],
})
export class UserComponent implements OnInit, OnDestroy, AfterViewInit
{
    static ngAcceptInputType_showAvatar: BooleanInput;

    @Input() showAvatar: boolean = true;
    user: User;
    initial: string;
    adminRole = false;
    apiUrl = environment.apiUrl;

    isModalOpen = false;

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    @Input() appearence!: "dense" | "default";
    menuData: NavigationItem[];

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _userService: UserService,
        private _authService: AuthService,
        private _matDialog: MatDialog,
        private renderer: Renderer2
    )
    {


    }


    /**
     * On init
     */
    ngOnInit(): void
    {
      this.menuData = [
        {
          title: 'ASF Premium',
          subtitle: ' ',
          type: 'group',
          children: [
            {
              id: 'commission.asf-premium.commission',
              title: 'Rozliczenia',
              type: 'basic',
              icon: 'heroicons_outline:chart-bar',
              link: '/rozliczenia/asf-premium',
              exactMatch: true,
            }]
        }];
    }

    ngAfterViewInit(): void
    {
      this.user = this._authService.user;
      this.initial = this.user.firstName[0] +  this.user.lastName[0];
      this.user.roles.forEach((response) => {
              if(response == 'ROLE_ADMIN'){
                this.adminRole = true;
              }
      })
      this._changeDetectorRef.markForCheck();

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    /**
     * Sign out
     */
    signOut(): void
    {
        this._authService.signOut();
    }

    getProfile(): void
    {
      this._authService.getProfile();
    }

  addSubmission(){
    this.isModalOpen = true;

    const dialogRef = this._matDialog.open(SubmissionsAddDialogComponent, {
      autoFocus: false,
      maxHeight: '90vh',
      panelClass: 'join-self-billing-dialog',
      closeOnNavigation: true,
      data: {
        // partnerProfile: partnerProfile,
        // company: company,
        // contract: contractUuid
      },
    })

    // Po otwarciu modala ukryj pozostałe elementy
    this.setAccessibilityForDialog(true);

    dialogRef.afterClosed().subscribe(() => {
      this.isModalOpen = false;

      // Przywróć dostępność elementów po zamknięciu modala
      this.setAccessibilityForDialog(false);
    });
  }

  setAccessibilityForDialog(isModalOpen: boolean) {
    const mainContent = document.querySelector('.app-content');  // Główna zawartość strony
    if (mainContent) {
      if (isModalOpen) {
        // Ukryj główną zawartość dla czytników ekranu
        this.renderer.setAttribute(mainContent, 'aria-hidden', 'true');
        this.renderer.setAttribute(mainContent, 'inert', 'true'); // Blokuj interakcje
      } else {
        // Przywróć interakcje i dostępność po zamknięciu modala
        this.renderer.removeAttribute(mainContent, 'aria-hidden');
        this.renderer.removeAttribute(mainContent, 'inert');
      }
    }
  }
}
