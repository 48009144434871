/* eslint-disable */
import {NavigationItem} from "../../../shared/components/navigation";

export const defaultNavigation: NavigationItem[] = [
    {
        id      : 'home',
        title   : '',
        subtitle: '',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'home.project',
                title: 'Aktualności',
                type : 'basic',
                icon : 'heroicons_outline:newspaper',
                link : '/home',
                permission: 'home',
                ofwcaView: true,
                partnerView: true
            },
          {
            id   : 'home.project',
            title: 'Sprzedaż',
            type : 'basic',
            icon : 'heroicons_outline:arrow-trending-up',
            link : '/sprzedaz',
            permission: 'registration',
            ofwcaView: true,
            partnerView: false
          },
            {
              id   : 'home.project',
              title: 'Rozliczenia',
              type : 'basic',
              icon : 'heroicons_outline:chart-bar',
              link : '/rozliczenia',
              permission: 'settlement',
              ofwcaView: false,
              partnerView: true
            },
  {
            id   : 'home.project',
            title: 'Należności',
            type : 'collapsable',
            link : '',
            icon : 'heroicons_outline:banknotes',
            permission: 'receivables',
            ofwcaView: true,
            partnerView: true,
            children: [
              {
                id   : 'home.project.historia',
                title: 'Historia',
                type : 'basic',
                icon : '',
                link : 'naleznosci/historia',
                permission: 'receivables',
                ofwcaView: true,
                partnerView: true,
              },
              {
                id   : 'home.project.polisy',
                title: 'Polisy',
                type : 'basic',
                icon : '',
                link : 'naleznosci/polisy',
                permission: 'receivables',
                ofwcaView: true,
                partnerView: true,
              },
],
          },
            {
              id   : 'home.project',
              title: 'Pracownicy',
              type : 'basic',
              icon : 'heroicons_outline:user-group',
              link : '/pracownicy',
              permission: 'ofwca',
              ofwcaView: false,
              partnerView: true
            },
            {
              id   : 'home.project',
              title: 'Umowy',
              type : 'basic',
              icon : 'heroicons_outline:document-check',
              link : '/umowy',
              permission: 'contract',
              ofwcaView: false,
              partnerView: true
            },
          {
            id: 'break',
            title: '',
            subtitle: '',
            type: 'divider',
            icon: '',
          },
        ],
    },
  {
    id : 'settings',
    title   : '',
    subtitle: '',
    type    : 'group',
    icon    : 'heroicons_outline:chevron-double-down',
    children: [
      // {
      //   id   : 'home.project',
      //   title: 'Ustawienia',
      //   type : 'basic',
      //   icon : 'heroicons_outline:cog-6-tooth',
      //   link : '/home2',
      //   permission: 'home',
      // },
      // {
      //   id   : 'home.project',
      //   title: 'Zgłoś zmianę',
      //   type : 'basic',
      //   icon : 'heroicons_outline:megaphone',
      //   link : '/home3',
      //   permission: 'home',
      // },
      {
        id: 'break',
        title: '',
        subtitle: '',
        type: 'divider',
        icon: '',
      },
    ]

  }
];
export const compactNavigation: NavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'Dashboards',
        tooltip : 'Dashboards',
        type    : 'aside',
        icon    : 'heroicons_outline:home',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation-api
    },
    {
        id      : 'apps',
        title   : 'Apps',
        tooltip : 'Apps',
        type    : 'aside',
        icon    : 'heroicons_outline:qrcode',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation-api
    },
    {
        id      : 'pages',
        title   : 'Pages',
        tooltip : 'Pages',
        type    : 'aside',
        icon    : 'heroicons_outline:document-duplicate',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation-api
    },
    {
        id      : 'user-interface',
        title   : 'UI',
        tooltip : 'UI',
        type    : 'aside',
        icon    : 'heroicons_outline:rectangle-stack',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation-api
    },
    {
        id      : 'navigation-api-features',
        title   : 'Navigation',
        tooltip : 'Navigation',
        type    : 'aside',
        icon    : 'heroicons_outline:bars-3',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation-api
    },
];
export const futuristicNavigation: NavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'DASHBOARDS',
        type    : 'group',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation-api
    },
    {
        id      : 'apps',
        title   : 'APPS',
        type    : 'group',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation-api
    },
    {
        id   : 'others',
        title: 'OTHERS',
        type : 'group',
    },
    {
        id      : 'pages',
        title   : 'Pages',
        type    : 'aside',
        icon    : 'heroicons_outline:document-duplicate',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation-api
    },
    {
        id      : 'user-interface',
        title   : 'User Interface',
        type    : 'aside',
        icon    : 'heroicons_outline:rectangle-stack',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation-api
    },
    {
        id      : 'navigation-api-features',
        title   : 'Navigation Features',
        type    : 'aside',
        icon    : 'heroicons_outline:bars-3',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation-api
    },
];
export const horizontalNavigation: NavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'Dashboards',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation-api
    },
    {
        id      : 'apps',
        title   : 'Apps',
        type    : 'group',
        icon    : 'heroicons_outline:qrcode',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation-api
    },
    {
        id      : 'pages',
        title   : 'Pages',
        type    : 'group',
        icon    : 'heroicons_outline:document-duplicate',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation-api
    },
    {
        id      : 'user-interface',
        title   : 'UI',
        type    : 'group',
        icon    : 'heroicons_outline:rectangle-stack',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation-api
    },
    {
        id      : 'navigation-api-features',
        title   : 'Misc',
        type    : 'group',
        icon    : 'heroicons_outline:bars-3',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation-api
    },
];
export const mobileMenuNavigation: NavigationItem[] = [

  {
    id      : 'id-mobile-home',
    title   : 'Główna',
    type    : 'basic',
    icon    : 'heroicons_outline:home',
    link : '/home',
    // children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation-api
  },
  {
    id      : 'id-mobile-settlement',
    title   : 'Rozliczenia',
    type    : 'basic',
    icon    : 'heroicons_outline:chart-bar',
    link    : '/rozliczenia',
    permission : 'settlement'
    // children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation-api
  },
  {
    id      : 'id-mobile-receivables',
    title   : 'Naleznosci',
    type    : 'collapsable',
    icon    : 'heroicons_outline:banknote',
    link    : '/naleznosci',
    permission : 'settlement'
    // children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation-api
  },
  {
    id      : 'id-mobile-work',
    title   : 'Pracownicy',
    type    : 'basic',
    icon    : 'heroicons_outline:user-group',
    link    : '/pracownicy',
    permission: 'ofwca'

    // children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation-api
  },
  {
    id      : 'id-mobile-menu',
    title   : 'Menu',
    type    : 'basic',
    icon    : 'heroicons_outline:bars-3',
    activeMenuNavigation : true,
    permission: 'home'
    // children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation-api
  },
];
