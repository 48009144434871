<button matDialogClose type="button" data-drawer-dismiss="drawer-example" aria-controls="drawer-example" class="z-99 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm p-1.5 absolute top-1 right-1.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
  <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
       xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"></path>
  </svg>
  <span class="sr-only">Zamknij</span>
</button>
<div class="flex w-full flex-col p-3 overflow-y-hidden min-h-full h-full" >
<div class=" w-full gap-x-4 " >
  <div class="grid-container ">
    <h1 class=" breakingSection font-sans text-center text-xl mx-auto font-extrabold leading-tight text-asf360Primary-600 sm:text-3xl lg:text-2xl rounded-2xl ">
      Towarzystwa aktywne
    </h1>
      <div class="grid-item h-full min-h-full" (click)="navigateToPortal(item.link)" *ngFor="let item of activeCompanies">
        <div class=" image-container flex justify-center items-center w-full">
            <img
              *ngIf="!isDarkMode"
              class="justify-start flex p-3 min-h-[50px]"
              src="assets/images/insurancecompanies/{{item?.key}}/{{item?.key}}.png"
              alt = "Zdjęcie" (load)="imageLoaded(item.id)" loading="lazy" title="{{item.name}}">
            <img
              *ngIf="isDarkMode"
              class="justify-start flex p-3 min-h-[50px]"
              src="assets/images/insurancecompanies/{{item?.key}}/{{item?.key}}-dark.png"
              alt = "Zdjęcie" (load)="imageLoaded(item.id)" loading="lazy" title="{{item.name}}">

<!--            <img src="{{apiUrl}}/rest/crm/asf24/insurance-company/{{item?.id}}/image" alt = "Zdjęcie" (load)="imageLoaded(item.id)" loading="lazy" title="{{item.name}}"/>-->
            @if(!loadedImgs.includes(item.id)){
              <div class="centercircle2 relative  w-[32px] h-[32px]">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 85 85" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M42.96 21.07C31.3 21.07 21.84 30.52 21.84 42.19C21.84 53.85 31.3 63.31 42.96 63.31C54.63 63.31 64.08 53.85 64.08 42.19C64.08 30.53 54.63 21.07 42.96 21.07ZM42.96 84.23C19.74 84.23 0.920044 65.41 0.920044 42.19C0.920044 18.97 19.74 0.149994 42.96 0.149994C66.18 0.149994 85 18.97 85 42.19C85 65.41 66.18 84.23 42.96 84.23Z" fill="url(#paint0_linear_270_11507)"/>
                  <defs>
                    <linearGradient id="paint0_linear_270_11507" x1="0.920044" y1="42.19" x2="85" y2="42.19" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#D63A15"/>
                      <stop offset="0.2" stop-color="#D63A15"/>
                      <stop offset="0.65" stop-color="#ED6D14"/>
                      <stop offset="1" stop-color="#ED6D14"/>
                    </linearGradient>
                  </defs>
                </svg>
                <div class="orbitcircle2 bottom-[44px] relative">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 21 22" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.54 0.23999C4.76998 0.23999 0.0799561 4.91999 0.0799561 10.7C0.0799561 16.48 4.75998 21.16 10.54 21.16C16.31 21.16 21 16.48 21 10.7C21 4.92999 16.32 0.23999 10.54 0.23999Z" fill="url(#paint0_linear_270_11511)"/>
                    <defs>
                      <linearGradient id="paint0_linear_270_11511" x1="0.0899561" y1="10.69" x2="21" y2="10.69" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#D63A15"/>
                        <stop offset="0.2" stop-color="#D63A15"/>
                        <stop offset="0.65" stop-color="#ED6D14"/>
                        <stop offset="1" stop-color="#ED6D14"/>
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
              </div>
<!--              <app-load-animation></app-load-animation>-->
            }
            </div>
      </div>
    <h1 class=" breakingSection font-sans text-center text-xl mx-auto font-extrabold leading-tight text-asf360Primary-600 sm:text-3xl lg:text-2xl rounded-2xl ">
      Towarzystwa nieaktywne
    </h1>
    @for(item of restCompanies; track item){
      <div class="grid-item-unactive">
        <div class="image-container items-center">
          <img
            *ngIf="!isDarkMode"
            class="justify-start flex p-3 min-h-[50px]"
            src="assets/images/insurancecompanies/{{item?.key}}/{{item?.key}}.png"
            alt = "Zdjęcie" (load)="imageLoaded(item.id)" loading="lazy" title="{{item.name}}">
          <img
            *ngIf="isDarkMode"
            class="justify-start flex p-3 min-h-[50px]"
            src="assets/images/insurancecompanies/{{item?.key}}/{{item?.key}}-dark.png"
            alt = "Zdjęcie" (load)="imageLoaded(item.id)" loading="lazy" title="{{item.name}}">
          @if(!loadedImgs.includes(item.id)){
            <div class="centercircle2 relative  w-[32px] h-[32px]">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 85 85" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M42.96 21.07C31.3 21.07 21.84 30.52 21.84 42.19C21.84 53.85 31.3 63.31 42.96 63.31C54.63 63.31 64.08 53.85 64.08 42.19C64.08 30.53 54.63 21.07 42.96 21.07ZM42.96 84.23C19.74 84.23 0.920044 65.41 0.920044 42.19C0.920044 18.97 19.74 0.149994 42.96 0.149994C66.18 0.149994 85 18.97 85 42.19C85 65.41 66.18 84.23 42.96 84.23Z" fill="url(#paint0_linear_270_11507)"/>
                <defs>
                  <linearGradient id="paint0_linear_270_11507" x1="0.920044" y1="42.19" x2="85" y2="42.19" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D63A15"/>
                    <stop offset="0.2" stop-color="#D63A15"/>
                    <stop offset="0.65" stop-color="#ED6D14"/>
                    <stop offset="1" stop-color="#ED6D14"/>
                  </linearGradient>
                </defs>
              </svg>
              <div class="orbitcircle2 bottom-[49px] relative">
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 21 22" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.54 0.23999C4.76998 0.23999 0.0799561 4.91999 0.0799561 10.7C0.0799561 16.48 4.75998 21.16 10.54 21.16C16.31 21.16 21 16.48 21 10.7C21 4.92999 16.32 0.23999 10.54 0.23999Z" fill="url(#paint0_linear_270_11511)"/>
                  <defs>
                    <linearGradient id="paint0_linear_270_11511" x1="0.0899561" y1="10.69" x2="21" y2="10.69" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#D63A15"/>
                      <stop offset="0.2" stop-color="#D63A15"/>
                      <stop offset="0.65" stop-color="#ED6D14"/>
                      <stop offset="1" stop-color="#ED6D14"/>
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
<!--            <app-load-animation></app-load-animation>-->
          }
        </div>
      </div>
    }

  </div>
</div>
</div>
