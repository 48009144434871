import {Component, HostListener, OnInit} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {AuthService} from "./shared/services/auth.service";

@Component({
  selector   : 'app-root',
  templateUrl: './app.component.html',
  styleUrls  : ['./app.component.scss'],
  standalone : true,
  imports    : [RouterOutlet],
})
export class AppComponent
{
  /**
   * Constructor
   */
  constructor(

  )
  {
  }

  fontSize: string = 'text-sm';

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    const width = event.target.innerWidth;

    if (width < 640) {
      this.fontSize = 'text-sm';
    } else if (width >= 640 && width < 768) {
      this.fontSize = 'text-md';
    } else if (width >= 768 && width < 1024) {
      this.fontSize = 'text-lg';
    } else {
      this.fontSize = 'text-xl';
    }
  }
}
