import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Observable, Subject, takeUntil} from "rxjs";
import {InsuranceCompany} from "../../../interface/insurance-company";
import {environment} from "../../../../../environments/environment.development.360";
import {InsuranceCompanyService} from "../../../services/insurance-company.service";
import {UserService} from "../../../services/user/user.service";
import {MatDialogClose, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatMenuItem} from "@angular/material/menu";
import {RouterLink} from "@angular/router";
import {MatDivider} from "@angular/material/divider";
import {NgForOf, NgIf} from "@angular/common";
import {Scheme} from "../../../config/config.types";
import {RegistrationService} from "../../../services/registration.service";

@Component({
  selector: 'app-user-all-companies-dialog',
  standalone: true,
  imports: [
    FormsModule,
    MatDialogClose,
    MatMenuItem,
    ReactiveFormsModule,
    RouterLink,
    MatDivider,
    NgForOf,
    NgIf,
  ],
  templateUrl: './user-all-companies-dialog.component.html',
  styleUrl: './user-all-companies-dialog.component.scss'
})
export class UserAllCompaniesDialogComponent implements OnInit{
  readonly String = String;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  apiUrl = environment.apiUrl;
  activeCompanies: InsuranceCompany[];
  restCompanies: any[];
  isDarkMode: boolean;
  loadedImgs = [];
  insuranceCompanys2$: Observable<InsuranceCompany[]>;
  notRegistered: Observable<InsuranceCompany[]>;
  insuranceCompanys2: InsuranceCompany[];

  constructor (
    private _userService: UserService,
    private _insuranceService: InsuranceCompanyService,
    private _registrationService: RegistrationService,
  ){
  }

  ngOnInit(): void {

    this.insuranceCompanys2$ = this._insuranceService.activeInsuranceCompanys$
    this.insuranceCompanys2$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((insuranceCompany) => {
        this.insuranceCompanys2 = insuranceCompany;
      });

    this.notRegistered = this._registrationService.registrationsOtherThanRegistered$;
    this.notRegistered
      .subscribe(val => {
    this.restCompanies = val;
    })


    this._userService.isDarkMode$
      .pipe()
      .subscribe((isDarkMode: boolean) => {
        const scheme: Scheme = isDarkMode ? 'dark' : 'light';
        this.isDarkMode = scheme === 'dark';
      })

    const userData = localStorage.getItem('user');
    const user = JSON.parse(userData);

    this.activeCompanies = this.insuranceCompanys2.filter(item1 =>
      user.permissionRegistration.some(item2 => item2 === item1.id && !item1.inactive && item1.link)    //sprawdzam id z permissionRegistration oraz status aktywna oraz czy ma link
    );
    this.activeCompanies = this.activeCompanies.filter((item, index, array) => {
      return!array.some((otherItem, otherIndex) => otherIndex < index && otherItem.id === item.id);
    });

    this.restCompanies = this.restCompanies.filter((item, index, array) => {
      return!array.some((otherItem, otherIndex) => otherIndex < index && otherItem.id === item.id);
    });


  }

  insuranceCompanyFinder(id: number): InsuranceCompany {
    const uid = id.toString();
    return this.insuranceCompanys2.find(u => String(u.id) === uid);
  }

  imageLoaded(id: any): void{
    this.loadedImgs.push(id);
    this.insuranceCompanyFinder(id);
  }
  navigateToPortal(link: any) {
    window.open(link, '_blank');
  }

}
