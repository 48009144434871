<div class="flex xl:flex-row flex-col p-6 gap-x-4 max-w-full bg-white dark:bg-asf360Shark-900 overflow-y-auto xl:overflow-y-hidden">
    <div class="w-full flex flex-col justify-center items-center h-full min-h-full">
      <h1
        class="font-sans text-center text-xl mx-auto font-extrabold leading-tight text-asf360Primary-600 sm:text-3xl lg:text-2xl rounded-2xl pb-2">
        Wybierz ulubione TU
      </h1>
      <button matDialogClose type="button" data-drawer-dismiss="drawer-example" aria-controls="drawer-example"
              class="z-99 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm p-1.5 absolute top-1 right-1.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-asf360Primary-600">
        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
             xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"></path>
        </svg>
        <span class="sr-only">Zamknij</span>
      </button>
      <div class="grid-container p-4 bg-white dark:bg-asf360Shark-900 ">
        @for (item of insuranceCompanys; track item; ) {
          <div class="grid-item"
               [ngClass]="{'selected': selected?.includes(String(item?.id)) && loadedImgs?.includes(item?.id)}"
               (click)="selectItem(item)">
            <div class=" image-container flex justify-center items-center w-full">
              <img
                *ngIf="!isDarkMode"
                class="justify-start flex p-3 min-h-[50px]"
                src="assets/images/insurancecompanies/{{item?.key}}/{{item?.key}}.png"
                alt="Zdjęcie" (load)="imageLoaded(item.id)" loading="lazy" title="{{item.name}}">
              <img
                *ngIf="isDarkMode"
                class="justify-start flex p-3 min-h-[50px]"
                src="assets/images/insurancecompanies/{{item?.key}}/{{item?.key}}-dark.png"
                alt="Zdjęcie" (load)="imageLoaded(item.id)" loading="lazy" title="{{item.name}}">
            </div>
          </div>
        }
      </div>
  </div>
  <div class="w-full flex flex-col">
      <h1
        class="font-sans text-center text-xl mx-auto font-extrabold leading-tight text-asf360Primary-600 sm:text-3xl lg:text-2xl rounded-2xl pb-5 ">
        Kolejność wyświetlania
      </h1>

    <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
      @for(item of selected; track item){
        <div class="flex flex-row example-box  gap-1 justify-between items-center   border rounded-xl border-asf360Primary-600 p-2" cdkDrag>
          <div class=" flex flex-row gap-1 items-center" >
            <img
              *ngIf="!isDarkMode"
              src="assets/images/insurancecompanies/{{insuranceCompanyKeyFinder(item)}}/{{insuranceCompanyKeyFinder(item)}}.png"
              alt="Zdjęcie"
              class="w-14 h-10 p-1 object-contain"
            />
            <img
              *ngIf="isDarkMode"
              src="assets/images/insurancecompanies/{{insuranceCompanyKeyFinder(item)}}/{{insuranceCompanyKeyFinder(item)}}-dark.png"
              alt="Zdjęcie"
              class="w-14 h-10 p-1 object-contain"
              />
            <p class="dark:text-white text-sm font-normal leading-none ">{{ insuranceCompanyNameFinder(item)}}</p>
          </div>
            <button (click)="removeFromSelected(item)">
              <mat-icon class="icon-size-5 text-black dark:text-white "
                        [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
            </button>
      </div>
      }
    </div>
  </div>

</div>

