import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IsActiveMatchOptions, RouterLink, RouterLinkActive } from '@angular/router';
import { NavigationService } from '../../../navigation.service';
import { NavigationItem } from '../../../navigation.types';
import { Subject, takeUntil } from 'rxjs';
import {VerticalNavigationComponent} from "../../vertical.component";
import {UtilsService} from "../../../../../services/config/utils";
import {UserPermissions} from "../../../../../interface/user-permissions";

@Component({
    selector       : 'vertical-navigation-basic-item',
    templateUrl    : './basic.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone     : true,
    imports        : [NgClass, NgIf, RouterLink, RouterLinkActive, MatTooltipModule, NgTemplateOutlet, MatIconModule],
})
export class VerticalNavigationBasicItemComponent implements OnInit, OnDestroy
{
  @Input() item: NavigationItem;
  @Input() name: string;

  isActiveMatchOptions: IsActiveMatchOptions;
  userPermission: UserPermissions = null;

  private _verticalNavigationComponent: VerticalNavigationComponent;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _navigationService: NavigationService,
    private _utilsService: UtilsService,
  ) {
    this.userPermission = JSON.parse(localStorage.getItem('userPermission'));
    // Set the equivalent of {exact: false} as default for active match options.
    // We are not assigning the item.isActiveMatchOptions directly to the
    // [routerLinkActiveOptions] because if it's "undefined" initially, the router
    // will throw an error and stop working.
    this.isActiveMatchOptions = this._utilsService.subsetMatchOptions;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void
  {
    if (this.item.ofwcaView && this.item.partnerView)
    {
      if (!localStorage.getItem('partnerUuid') && !localStorage.getItem('ofwcaUuid'))
      {
        this.item.disabled = true;
      }
    }

    if (this.item.ofwcaView && !this.item.partnerView && !localStorage.getItem('ofwcaUuid'))
    {

        this.item.disabled = true;

    }

    if (!localStorage.getItem('partnerUuid') && this.item.partnerView && !this.item.ofwcaView)
    {
        this.item.disabled = true;
    }

    if (this.item.permission == 'home'){
      this.item.disabled = false;
    }

    if (this.item.permission == 'settlement' &&
      (!this.userPermission.settlement.read )
    ){
      this.item.disabled = true;
    }

    if (this.item.permission == 'registration' &&
      (!this.userPermission.registration.read )
    ){
      this.item.disabled = true;
    }

    if (this.item.permission == 'receivables'  &&
      (!this.userPermission.receivables.read  || !localStorage.getItem('receivablesBalance'))
    ){
      this.item.disabled = true;
    }

    if (this.item.permission == 'ofwca' &&
      (!this.userPermission.ofwca.read )
    ){
      this.item.disabled = true;
    }

    if (this.item.permission == 'contract' &&
      (!this.userPermission.contract.read )
    ){
      this.item.disabled = true;
    }
    // Set the "isActiveMatchOptions" either from item's
    // "isActiveMatchOptions" or the equivalent form of
    // item's "exactMatch" option
    this.isActiveMatchOptions =
      this.item.isActiveMatchOptions ?? this.item.exactMatch
        ? this._utilsService.exactMatchOptions
        : this._utilsService.subsetMatchOptions;

    // Get the parent navigation-api component
    this._verticalNavigationComponent = this._navigationService.getComponent(this.name);

    // Mark for check
    this._changeDetectorRef.markForCheck();

    // Subscribe to onRefreshed on the navigation-api component
    this._verticalNavigationComponent.onRefreshed.pipe(
      takeUntil(this._unsubscribeAll),
    ).subscribe(() =>
    {
      // Mark for check
      this._changeDetectorRef.markForCheck();
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
