import { NgIf } from '@angular/common';
import {AfterViewInit, Component, ElementRef, inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {ActivatedRoute, Navigation, Router, RouterLink, RouterOutlet} from '@angular/router';
import {Subject, take, takeUntil} from 'rxjs';
import {
  HorizontalNavigationComponent,
  NavigationItem,
  NavigationService
} from "../../../../shared/components/navigation";
import {VerticalNavigationComponent} from "../../../../shared/components/navigation";
import {NavigationApiService} from "../../../../shared/services/navigation-api/navigation-api.service";
import {LoadingBarComponent} from "../../../../shared/components/loading-bar";
import {ApiNavigation} from "../../../../shared/services/navigation-api/navigation-api.types";
import {MediaWatcherService} from "../../../../shared/services/config/media-watcher";
import {UserComponent} from "../../../common/user/user.component";
import {
  MobileNavigationComponent
} from "../../../../shared/components/navigation/mobile/mobile.component";
import {
  SettingsNavigationComponent
} from "../../../../shared/components/settings/settings-navigation/settings-navigation.component";
import {AsfFullscreenComponent} from "../../../../shared/components/fullscreen/fullscreen.component";
import {UserService} from "../../../../shared/services/user/user.service";
import {MatTooltip} from "@angular/material/tooltip";
import {
  SubmissionsAddComponent
} from "../../../../shared/components/submissions/submissions-add/submissions-add.component";
import {Scheme} from "../../../../shared/config/config.types";
import {UserNavigationComponent} from "../../../../shared/components/user/user-navigation/user-navigation.component";

@Component({
    selector     : 'dense-layout',
    templateUrl  : './dense.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone   : true,
  imports: [LoadingBarComponent, MatButtonModule, MatIconModule, NgIf, RouterOutlet, VerticalNavigationComponent, UserComponent, MobileNavigationComponent, SettingsNavigationComponent, HorizontalNavigationComponent, AsfFullscreenComponent, RouterLink, MatTooltip, SubmissionsAddComponent, UserNavigationComponent],
})
export class DenseLayoutComponent implements OnInit, AfterViewInit,OnDestroy
{
    isScreenSmall: boolean;
    navigation: ApiNavigation;
    navigationAppearance: 'dense' | 'default' = 'default';
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    isDarkMode: boolean = false;
    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _apiNavigationService: NavigationApiService,
        private _mediaWatcherService: MediaWatcherService,
        private _navigationService: NavigationService,
        private _userService: UserService,
        private el: ElementRef
    )
    {
    }

    ngAfterViewInit(): void {
      this.checkParentForDarkClass();

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

      // Subscribe to navigation-api data
        this._apiNavigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: ApiNavigation) =>
            {
                this.navigation = navigation;
            });

        // Subscribe to media changes
        this._mediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) =>
            {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
                this._userService.isDropDownMenu$
                  .pipe()
                  .subscribe((vale) => {
                    const nav = vale ? 'dense' : 'default'
                    this.navigationAppearance = this.isScreenSmall ? 'default': nav ;
                  })

            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation-api
     *
     * @param name
     */
    toggleNavigation(name: string): void
    {
        // Get the navigation-api
        const navigation = this._navigationService.getComponent<VerticalNavigationComponent>(name);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    /**
     * Toggle the navigation-api appearance
     */
    toggleNavigationAppearance(): void
    {
      const navigationAppearance = (this.navigationAppearance === 'dense' ? 'default' : 'dense');
      navigationAppearance == 'dense' ? this._userService.setDropDownMenuDense() : this._userService.setDropDownMenuDefault()
      this.navigationAppearance = navigationAppearance;
      this._userService.editUserSetting().pipe().subscribe();
    }

    //DarkMode Check
  checkParentForDarkClass(): void {
    this._userService.isDarkMode$
      .pipe()
      .subscribe((isDarkMode: boolean) => {
        const scheme: Scheme = isDarkMode ? 'dark' : 'light';
        this.isDarkMode = scheme === 'dark';
      })
  }
}
