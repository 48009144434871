<div class="grid grid-cols-1 gap-3 justify-items-start">
  <div *ngIf="config.scheme !== 'dark'"
    class="flex items-center p-3  rounded-full cursor-pointer "
    [class.ring-2]="config.scheme === 'dark'" [matTooltip]="'Tryb ciemny'"
    (click)="setScheme('dark')">
    <div class="flex items-center rounded-full overflow-hidden">
      <mat-icon
        class="icon-size-5"
        [svgIcon]="'heroicons_outline:moon'"></mat-icon>
    </div>
    <div [class.text-secondary]="config.scheme !== 'dark'"></div>
  </div>

  <!-- Light -->
  <div *ngIf="config.scheme !== 'light'"
    class="flex items-center p-3 rounded-full cursor-pointer "
    [class.ring-2]="config.scheme === 'light'" [matTooltip]="'Tryb jasny'"
    (click)="setScheme('light')">
    <div class="flex items-center rounded-full overflow-hidden">
      <mat-icon
        class="icon-size-5"
        [svgIcon]="'heroicons_outline:sun'"></mat-icon>
    </div>
    <div [class.text-secondary]="config.scheme !== 'light'"></div>
  </div>

</div>
