<!--<img-->
<!--  class="h-auto rounded-xl z-10" onerror="this.src='images/not_found.png';"-->
<!--  [attr.src]="imageUrl | authImage | async"-->
<!--  [width]="(image.metadata.width ? image.metadata?.width: 'auto')"-->
<!--  [height]="(image.metadata.height ? image.metadata?.height: 'auto')"-->
<!--/>-->

@if (imagePost && imagePost.type.key == imageType){
  @defer (){
  <img
    class="h-auto rounded-xl z-10 dark:bg-asf360Shark-100 bg-asf360Shark-50 border-0"
    [attr.src]="'/rest/crm/post_attachment/' + imagePost.uuid + '/image' | authImage | async"
    [width]="(imagePost.metadata.width ? imagePost.metadata?.width: 'auto')"
    [height]="(imagePost.metadata.height ? imagePost.metadata?.height: 'auto')"
  />
  } @loading (minimum 1000){
    <skeleton
      [class]="'rounded-xl'"
      [rounded]="'0.75rem'"
      [height]="(imagePost.metadata.height - imagePost.metadata.height / 3)+ 'px'"
    ></skeleton>
  }
} @else if (imagePostTagGraphics){
  @defer (){
  <img
    class="h-auto rounded-xl z-10 bg-asf360Shark-100 border-2 border-asf360Shark-100"
    [attr.src]="'/rest/crm/tag/graphics/' + imagePostTagGraphics.graphicsUuid + '/image' | authImage | async"
    [width]="640"
    [height]="320"
  />
  } @loading (minimum 1000){
    <skeleton
      [class]="'rounded-xl'"
      [rounded]="'0.75rem'"
      [height]="'213.5px'"
    ></skeleton>
  }
} @else if (userPost){
      @if ('/rest/crm/user/' + userPost.usersUuid + '/avatar' | authImage: true | async){
        <div [class]="' rounded-full z-10 bg-asf360Shark-100'">
          <img
            [class]="class + ' object-cover rounded-full' "
            [attr.src]="'/rest/crm/user/' + userPost.usersUuid + '/avatar' | authImage | async"
          />
        </div>
      } @else {
        <div [class]="class + ' flex justify-center items-center rounded-full z-10 bg-asf360Shark-100'">
          <span [innerHTML]="getInitialUserPost(userPost)"></span>
        </div>
      }
} @else if (user){
<!--  @if ('/rest/crm/user/' + user.userId + '/avatar' | authImage: true | async){-->
<!--      <img-->
<!--        [class]="class + ' object-cover rounded-full' "-->
<!--        [attr.src]="'/rest/crm/user/' + user.userId + '/avatar' | authImage | async"-->
<!--      />-->
<!--  } @else {-->
    <div [class]="class + ' text-black bg-asf360Shark-100  flex justify-center items-center rounded-full'">
      <span [innerHTML]="getInitialUser(user)"></span>
    </div>

}
