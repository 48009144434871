import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, first, map, Observable, of, ReplaySubject, switchMap, tap, throwError} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {FilterService} from "./filter-service.service";
import {DOCUMENT} from "@angular/common";
import {AuthService} from "./auth.service";
import {Dictionary} from "../interface/dictionary";
import {InsuranceCompany} from "../interface/insurance-company";
import {ListResponse} from "../interface/response/list-response";

@Injectable({
  providedIn: 'root'
})
export class InsuranceCompanyService {
  loaded = false;

   private _logo: BehaviorSubject<any> = new BehaviorSubject(null);
   private _insuranceCompanys: BehaviorSubject<InsuranceCompany[] | null> =
    new BehaviorSubject(null);
   private _activeInsuranceCompanys: BehaviorSubject<InsuranceCompany[] | null> =
    new BehaviorSubject(null);


  constructor(
    private _httpClient: HttpClient,
    private _filterService: FilterService,
    @Inject(DOCUMENT) private _document: Document,
    private _authService: AuthService,
  ) { }

  get logo$(): Observable<any| null> {
    return this._logo.asObservable();
  }

  clearLogo(){
    this._logo.next(null);
  }
  async transform(id: number) {
    const token = this._authService.accessToken;
    const headers = new HttpHeaders({'Authorization': `Bearer ${token}`});
    const src = '/rest/crm/asf24/insurance-company/' + id + '/image';
    const imageBlob = await this._httpClient.get(src, {headers, responseType: 'blob'}).toPromise();
    const reader = new FileReader();
    const promise = new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(imageBlob);
    })
    promise.then((src) => {
      this._logo.next(src);
    })

  }

  /**
   * Getter for dictionaries
   */
  get insuranceCompanys$(): Observable<InsuranceCompany[]> {
    return this._insuranceCompanys.asObservable()
  }
  /**
   * Getter for dictionaries
   */
  get activeInsuranceCompanys$(): Observable<InsuranceCompany[]> {
    return this._activeInsuranceCompanys.asObservable()
  }

  loadInsuranceCompanys(): Observable<ListResponse<InsuranceCompany>> {
    return this._httpClient.get<ListResponse<InsuranceCompany>>('/rest/crm/asf24/insurance-company')
      .pipe(
        tap((response) => {
          this.loaded = true;
          this._insuranceCompanys.next(response.items)
        })
      );
  }

  loadActiveInsuranceCompanys(): Observable<ListResponse<InsuranceCompany>> {
    return this._httpClient.get<ListResponse<InsuranceCompany>>('/rest/crm/insurance-company/portal' )
      .pipe(
        tap((response) => {
          this.loaded = true;
          this._activeInsuranceCompanys.next(response.items)
        })
      );
  }


  // getLogo(id: number): Observable<any> {
  //   return this._httpClient.get<any>( `/rest/crm/asf24/insurance-company/${id}/image`)
  //     .pipe(
  //       first(),
  //       map((logo) => {
  //         // Update the commission
  //         this._logo.next(logo);
  //         console.log(logo);
  //
  //         // Return the commission
  //         return logo;
  //       }),
  //       switchMap((commission) => {
  //         if (!commission) {
  //           return throwError(
  //             'Brak loga ' + id + '!'
  //           );
  //         }
  //
  //         return of(commission);
  //       })
  //     );
  // }
}
